/* tslint:disable */
import { Model } from 'src/app/shared/models/clux/model-legacy';

import { GenderType, PronounsType, UserAccessRoleType } from '../models/uba/profile/model';
import { DestinationType } from '../models/uba/profileConfiguration/model';

export class CommonConstants {
  static LoaderDiameter = 50;
  static OverviewChartDiameter = 110;
  static DefaultDialogWidth = 700;
  static uuidJoinLimit = 15;
  static loadMoreRecordsCount = 15;
  static individualSearchResultCount = 3;

  static appRoutes = {
    login: 'login',
    loginFederated: 'login/federated',
    netSuiteSSORedirect: 'NetSuiteSSO',
    loginFederatedIdPi: 'login/federatedIdPi',
    logout: 'logout',
    contact: 'contact',
    signupBase: 'signup',
    signup: 'signup/:case',
    twofaBase: 'for2FA',
    for2FA: 'for2FA/:case',
    forgotpassword: 'forgot-password',
    resetpassword: 'reset-password',
    twofactorBase: 'authentication',
    twofactorAuthentication: 'two-factor-authentication',
    twofactor: 'authentication/:id',
    addSupport: 'add-support',
    home: 'home',
    distributor: 'distributor',
    scheduleTransfer: 'schedule-transfer',
    overview: {
      overviewBase: 'home',
      viewAllCashAccountTrans: 'view-all-cash-account-trans'
    },
    settings: {
      settingsBase: 'settings',
      profile: 'profile',
      signinSecurity: 'signin-security',
      bankAccounts: 'bank-accounts',
      tascCard: 'card',
      payrollSchedule: 'payroll-schedule',
      divisions: 'divisions',
      classes: 'classes',
      manageUsers: 'manage-users'
    },
    enroll: {
      enrollBase: 'employees',
      viewEmployeeBase: ':individualId',
      enroll: 'enroll',
      managebase: 'manage',
      manage: 'manage/:add',
      addEmployee: 'add',
      approve: 'approve',
      personalInfo: 'personal-information',
      employmentInfo: 'employer-information',
      enrollEmployee: 'enrollEmployee',
      addEnrollInfo: 'enrollEmployee/:id',
      employmentInformation: 'employment-information',
      employeeAccountSummary: 'employee-account-summary',
      employeeTransactions: 'employee-transactions',
      employeeInvestments: 'employee-investments',
      terminate: 'terminate',
      vaccine: 'vaccine',
      eligibilityEventBase: 'eligibility-event',
      addEvent: 'new',
      editEvent: ':eligibilityEventId',
      reviewEvent: ':eligibilityEventId/review',
      eligibilityEvents: 'eligibility-events',
      viewEvents: 'view-events'
    },
    files: {
      filesBase: 'files',
      datafiles: 'data-files',
      documents: 'documents',
      templates: 'templates',
      contacts: 'contacts',
      fileSettings: 'file-settings'
    },
    plan: {
      planBase: 'plan',
      plans: 'plans',
      benefit: 'benefit',
      payment: 'payment',
      paymentWithId: 'payment/:id',
      planSummary: 'plan-summary',
      enrollmentInformation: 'enrollment-information',
      coveragePeriod: 'coverage-period',
      fundingMethod: 'funding-method',
      employeesList: 'employees-list',
      eligibility: 'eligibility',
      remittance: 'remittance',
      pricing: 'pricing',
      verifyPosting: 'verify',
      postingSummary: 'summary',
      postManually: 'post-manually',
      terminate: 'terminate',
      terminateEmployee: 'terminate/:id',
    },
    transactions: {
      transactionBase: 'transactions',
      funding: 'funding',
      fundFromCashAccount: 'fund-from-cash-account',
      remittanceAccount: 'remittance-account',
    },
    employeeDetails: {
      personalInformationBase: 'personal-information',
      personalInformation: 'personal-information/:id',
      employmentInformation: 'employment-information',
      information: 'information',
      employeeTransactions: 'employee-transactions',
      employeeInvestments: 'employee-investments',
      employeeAccountSummary: 'employee-account-summary',
      dependentInformation: 'dependents',
      employeeTascCard: 'card',
      employeeLetters: 'employee-letters',
    },
    support: {
      supportBase: 'support',
      viewSupportRequest: 'view/:requestId',
      supportRequests: 'support-requests',
      supportFaqs: 'support-faqs',
      userGuidesAndVideos: 'support-user-guides-and-videos',
    },
    sitemap: {
      sitemapBase: 'sitemap'
    },
    reports: {
      reportsBase: 'reports',
      reportType: 'reports/:type',
      enrollment: 'enrollment',
      accountActivity: 'account-activity',
      funding: 'funding',
      reconciliation: 'reconciliation',
      participantBalanceSummary: 'participant-balance-summary',
      pod: 'point-of-disbursement',
      paymentDetail: 'payment-detail',
      finalization: 'finalization',
      monthEndStatements: 'month-end-statements',
      continuationAca: 'continuation-aca',
      generalInitialNoticeSubstantiation: 'general-initial-notice-substantiation',
      coverageChange: 'coverage-change',
      paymentAccountSummary: 'payment-account-summary',
      planStructure: 'plan-structure',
      rateTableSummary: 'rate-table-summary',
      remittance: 'remittance',
    },
    nobrand: 'no-brand'
  };

  static errorCodes: Model.ErrorCodes = {
    NotAuthorizedException: 'The email and password combination you entered is incorrect. Please try again.',
    UserNotFoundException: 'No such user found, please create account.',
    UnknownError: 'Unknown error, the response body from fetch is: undefined',
    CodeMismatchException: 'Incorrect code. Please try again.',
    ExpiredCodeException: 'Expired code received for user',
    UserNotConfirmedException: 'User is not confirmed.',
    fileUploadSuccess: 'Submitted successfully. Refresh this page in a few minutes to confirm your changes.',
    dataFileUploadSuccess: 'Data File has been uploaded successfully.',
    fileUploadError: ' File did not uploaded successfully.',
    passwordChangeSuccess: 'Password successfully changed.',
    phoneNumberChangeSuccess: 'Phone number successfully changed.',
    codeSentSuccess: 'New code has been sent.',
    wrongPasswordError: 'The current password you have provided is incorrect.',
    invalidCredential: 'Incorrect credential. Please try again.',
    samePasswordError: 'New password cannot be same as old password. Please try again.',
    singleEnrolled: 'approved for enrollment.',
    allEnrolled: 'All {count} enrollments are approved.',
    singleRemoved: 'rejected for enrollment.',
    allRemoved: 'All {count} employee enrollments rejected.',
    benefitMaximumLimit: 'Contribution amount cannot be more than benefit plan maximum.',
    benefitMinimumLimit: 'Contribution amount cannot be less than benefit plan minimum.',
    blankValue: 'Value cannot be blank.',
    ErrorFromApi: 'Unknown error.',
    postingSuccess: 'Posting Successfully updated.',
    addExistEmployee: 'Employee successfully added.',
    addEmployee: 'Employee successfully added.',
    editEmployee: 'The employee information was successfully updated.',
    editEmployeePersonalInformation: 'Employee personal information was successfully updated.',
    deleteSuccess: 'File deleted successfully.',
    deleteError: 'There is issue during delete File.',
    noRecords: 'No records found.',
    invalidVerificationCode: 'Invalid verification code provided, please try again.',
    touMissingError: 'Something has gone wrong. Please contact customer support at 800-422-4611',
    verifyBenefitMaximumLimit: 'Amount cannot exceed $',
    noEditContribution: 'Benefit plan does not allow employer contributions.',
    noEditDestribution: 'Benefit plan does not allow employee contributions.',
    InvalidParameterException: 'Please <a class=\'growel-link\' href=\'/signup/fillform\'>sign up</a> to establish access to your account.',
    userConfirmationCodeExpired: 'Verification code is expired. A new verification code has been sent. This code is valid for 24 hours.',
    LimitExceededException: 'Attempt limit exceeded, please try after some time.',
    unconfirmedUserIncorrectPasswordException: 'Your email is already confirmed from an earlier incomplete sign up attempt. You need to reset your password to continue.',
    twoFAEnableSuccess: 'Two Factor Authentication enabled successfully.',
    twoFADisableSuccess: 'Two Factor Authentication disabled successfully.',
    electionUpdateSuccessEmployer: 'Employer contribution amount has been updated.',
    electionUpdateSuccessEmployee: 'Employee election amount has been updated.',
    electionUpdateSuccess: 'Your changes have been successfully submitted. Please allow a few minutes for these to be reflected online.',
    scheduleTransferSuccess: 'Transfer scheduled successfully.',
    scheduleTransferNotAllowed: 'Cash account available balance must be greater than minimum operating balance to initiate a transfer.',
    removeEmployeeServiceFailure: 'Termination could not be processed. Please contact {companyName} for assistance.',
    noPayrollIdError: 'Enrollment in a benefit plan requires a payroll schedule. Please assign a payroll schedule in the employee profile.',
    verifyPostingOnSuccess: 'Submitted successfully. Refresh this page in a few minutes to confirm your changes before making additional edits.',
    verifyFundCashAccountOnSuccess: 'Selected plans have successfully been funded.',
    manualHoldExist: 'Your account is on hold. Card and reimbursement activity will resume after the hold is cleared.',
    manualAndSystemHoldExists: 'There is a hold on your account with an exception, Card and reimbursement activity will continue while this remains in place. If the exception is removed, participant activity will stop until the hold is cleared or a new exception is added.',
    invalidDateError: 'Please enter a valid date.',
    disableToggleMessage: 'This function is not available for the number of records displayed.',
    apiFailure: 'Something went wrong. Please contact {companyName}.',
    backdatedElectionChange: 'You are about to make a backdated election change which may cause contributions to be reversed and recalculated. If additional funding is needed, it will be collected with the next scheduled posting date.',
    disbursableDateUpdateSuccess: 'Changes have been submitted successfully.',
    fileCountErrorMessage: 'Please select {maxFileLimit} or less files.',
    fileTypeSizeErrorMessage: 'Some files could not be attached. Files must be in CSV, JPG, JPEG, PNG, PDF, XLS, or XLSX format. Individual files cannot be more than 10 MB.',
    externalRouteMessage: 'You are now leaving this website and navigating to an external site.',
    participantBypassWarning: 'Changes made here will change the employee\'s total expected contribution amount.',
    clientBypassWarning: 'Changes made here will change the employer\'s total expected contribution amount.',
    duplicateReportInProgress: 'Report already in progress',
    updateComment: 'Your support request comment has been submitted',
    commentAttachmentsUploadError: 'One or more attachments have failed to Upload. Please try again.',
    addEvent: 'Save was successfull.',
    letterDownloadFailure: 'An error occured while attempting to download a letter.',
    missingDepDob: 'Selected dependents do not all have a date of birth selected. Please add dates of birth and try again.',
    missingSpouseDob: 'Spouse does not have a date of birth selected. Please add date of birth and try again.',
    missingIndividualDob: 'Individual does not have a date of birth selected. Please add date of birth and try again.',
    includedPartySsnsMissing: 'Social Security numbers are required for all included parties for this plan.',
  };
  static routeParams = {
    case: 'case',
    add: 'add'
  };

  static signupCases = {
    fillForm: 'fillform',
    userDetails: 'userDetails',
    enterMobile: 'enterMobile',
    verifyEmail: 'verify-email',
    verifyMobile: 'verify-mobile',
    verify2FAMobile: 'verify-2FA-mobile',
    successSignup: 'successSignup',
    userNotExist: 'userNotExist'
  };

  static sharedKeys = {
    routeKey: 'routeKey'
  };

  static sharedValues = {
    addEmployee: 'addEmployee',
    addExistEmployee: 'addExistEmployee',
    editEmployee: 'editEmployee',
    editEmployeePersonalInformation: 'editEmployeePersonalInformation',
    addEvent: 'addEvent',
  };

  static localKeys = {
    userData: 'CL_UD',
    signInMobile: 'CL_SI_MO',
    cognitoUserSession: 'CO_US',
    individualInfo: 'CL_IINFO',
    addedIndividualInfo: 'CL_AIINFO',
    indiviEmployeeInfo: 'CL_IEINFO',
    isExistUser: 'addExistUser',
    showPostManuallyGrowl: 'CL_SMG',
    userLastRoute: 'CL_ULR',
    showHoldGrowler: 'CL_SHG',
    currentBP: 'CL_CBP',
    fromDate: 'fromDate',
    netSuiteSSO: 'netSuiteSSO'
  };

  static emmiterKeys = { loginErrors: 'loginErrors', isHeader: 'isHeader' };

  static dialogData: Model.DialogDataModel = {
    requestIndividualLetter: {
      title: 'REQUEST LETTER',
      desc: 'Request letter template document generation',
    },
    addDependent: {
      title: 'ADD A DEPENDENT',
      desc: '',
      positionTop: '7%',
      width: '584px'
    },
    editDependent: {
      title: 'Edit DEPENDENT',
      desc: '',
      positionTop: '7%',
      width: '584px'
    },
    requestNewCard: {
      title: 'REQUEST A NEW CARD',
      desc:
        "I'd like to:"
    },
    changePassword: {
      title: 'Change Password',
      desc:
        'Enter your current password for verification and create your new password.',
      view: 'changePassword'
    },
    editMobileNumber: {
      title: 'Edit mobile phone',
      desc: 'To edit your mobile number, we have to verify it first.',
      view: 'editMobilenumber'
    },
    enterMobileNumber: {
      title: `<p>ENABLE TWO-FACTOR</p> <p>AUTHENTICATION?</p>`,
      desc: `<p class="mb-0">Two-factor authentication will prompt you to enter a code when you sign in on</p><p> a new device.</p>`,
      view: 'enterMobilenumber'
    },
    infoSignInSecurity: {
      title: 'More Info About SIGN IN AND SECURITY',
      desc: `<p>"Two factor authentication will prompt you to enter a code every time you sign in. Why?</p>
      <p>With your sign-in, you have access to employee's Personally Identifiable Information (PII) and <br/>
      Protected Health Information (PHI). To help ensure the security of that information, two-factor<br/>
      authentication is required."</p>
      `
    },
    payrollSchedule: {
      title: 'More Info About PAYROLL SCHEDULES',
      desc: `<p>View all employee payroll schedules and frequencies.</p>
      <p>Please contact us if you need to add, remove, or modify a schedule.</p>`
    },
    infoDataFiles: {
      title: 'More Info About Data Files',
      desc: `<p>After you enter information into a template, then save and upload it, it becomes a data file. From here, you can view all your uploaded data files, search for a specific file, and upload new ones.
      The file type is determined by the template.</p>
      <p>A data file can have one of four statuses:</p>
      <ol class="number">
        <li>Processing. The file has been uploaded and is in process. You won't see the information in that file reflected online yet.</li>
        <li>Processed Successfully. The file has been processed. We didn't find any errors, and you can see the information reflected online.</li>
        <li>Processed with Errors. Processing has been completed, but there were one or more errors found in the file. Go to Alerts for specific details.</li>
        <li>Processing Failed. We were unable to process the file due to errors. Go to Alerts for specific details.</li>
      </ol>
      `
    },
    benefitPlan: {
      title: 'More Info ABOUT PLANS',
      desc: `<p>View all of your plans and participating employees.</p>
      <p>This is also where you'll go to renew your benefit plans during the renewal period (which is determined based on benefit plan configuration).</p>`
    },
    templates: {
      title: 'MORE Info ABOUT TEMPLATES',
      desc: `<p>Coming Soon!</p>`
    },
    verifyPosting: {
      title: 'MORE Info ABOUT VERIFY POSTING',
      desc: `<p>View all posting information for a specific posting date.</p>
      <p>For future posting dates, you can edit individual amounts or make changes via file upload prior to posting (download posting data file, edit, upload posting data file).</p>`
    },
    coveragePeriod: {
      title: 'More Info ABOUT COVERAGE PERIOD',
      desc: `<p>If your benefit plan allow a plan extension period or runout period after the plan end date, those</p>
      <p>are shown here, along with their duration and end dates.</p>`
    },
    addAddress: {
      title: 'Add Address',
      desc: '',
      primaryButton: 'Add'
    },
    postingSummary: {
      title: 'More Info About Posting Verification Report Summary',
      desc: `<p>View a summary of posting information for a specific posting date, broken down by source and filterable by benefit plan.</p>
      <p>For details, go to Verify Posting.</p>`
    },
    paymentRequestReport: {
      title: 'More Info About Payment Request File',
      desc: `<p>View a summary of posting information for a specific posting date, broken down by source and filterable by benefit plan.</p>
      <p>For details, go to Verify Posting.</p>`
    },
    tascCard: {
      title: 'More Info About {companyCard}',
      desc: `<p>View {companyCard} settings for all benefit plans.</p>
      <p>From here, you can also request a new {companyCard} for a participant, lock or unlock a missing card, and report one as lost or stolen.</p>`
    },
    addContact: {
      title: 'ADD CONTACT',
      desc: '',
      primaryButton: 'Add'
    },
    editContact: {
      title: 'EDIT CONTACT',
      desc: '',
      primaryButton: 'Update'
    },
    postManually: {
      title: 'More INFO About Post Manually',
      desc: `<p>Initiate a manual posting for a scheduled posting date.</p>
     <p> If you have changes to make to individual amounts, go to Verify Posting first.</p>`
    },
    viewCashBalance: {
      title: 'More INFO About Cash Balance',
      desc: `<p>This is your cash account balance.</p>`
    },
    fundingTransactionsInfo: {
      title: 'MORE INFO ABOUT FUNDING TRANSACTIONS',
      desc: `<p>Coming soon.</p>`
    },
    bankAccountInfo: {
      title: 'MORE INFO ABOUT BANK ACCOUNTS',
      desc: `<p>This is a list of your bank accounts for invoices and billing and for benefit plan funding.</p>
      <p>Please contact us if you need to add a new bank account, or update or remove one listed here.</p>`
    },
    myDocuments: {
      title: 'More Info About My Documents',
      desc: `<p>Upload documents related to your other benefits, company policies, HR guidelines, etc. for easy, one-stop access.</p>`
    },
    divisions: {
      title: 'More Info About Divisions',
      desc: `<p>View all employee divisions (and subdivisions, if applicable). Please contact us if you need to make changes to this list.</p>`
    },
    classes: {
      title: 'More Info About Classes',
      desc: `<p>View all employee classes, along with eligibility information (waiting period and effective date) for each.</p>
      <p>Please contact us if you need to make changes to this list.</p>`
    },
    contributions: {
      title: 'More Info About Active Plans Contributions',
      desc: `<p>Coming soon.</p>`
    },
    reimbursement: {
      title: 'More Info About Reimbursement',
      desc: `<p>Coming soon.</p>`
    },
    expenditures: {
      title: 'More Info About Active Plans Expenditures',
      desc: `<p>Coming soon.</p>`
    },
    removeEmployees: {
      title: 'More Info',
      desc: `<p>When an individual leaves employment or stops participating in a benefit plan as a result of a qualified life event or other change, remove them here. Dates shown below may be defaults based on your benefit plan configuration.</p>`
    }
  };

  static fileUpload = {
    manageEmployees: {
      multiple: {
        headingTitle: 'Add Multiple Employees',
        headingDesc: 'Add multiple employees at once by <br> uploading a completed employee-census file.'
      },
      single: {
        headingTitle: 'Add Single Employee',
        headingDesc: 'Add one employee at a time by <br> manually entering their information.'
      },
      mainHeader: 'ADD EMPLOYEES',
      flleBrowseHeader: 'ADD MULTIPLE EMPLOYEES',
      fileBrowse: {
        uploadHeader: 'Upload employee-census file',
        downloadTemplatelink: 'Download employee-census file template,',
        downloadTemplateDesc:
          'enter the required information, save the file in .csv format and upload.',
        fileValidationTest:
          'Employee-census file must be in a .csv format and size cannot exceed 20 MB',
        dragDropText: 'Drag and drop file or use the \'Browse\' button below',
        fileRenameText:
          'The file is renamed to {companyName}\'s required naming convention.',
        fileType: 'Census',
        dialogData: {
          uploadingFile: {
            title: 'UPLOADING FILE',
            desc: 'This may take a minute...',
            view: 'uploadingFile'
          },
          errorAtUploadFile: {
            desc1: 'is not a valid file name.',
            desc2: 'Please rename your file to "Census.csv" and upload again.',
            title: '',
            desc: '',
            view: 'errorAtUploadFile'
          }
        }
      }
    },
    enrollEmployees: {
      multiple: {
        headingTitle: 'Enroll multiple employees',
        headingDesc:
          'Enroll multiple employees at once by <br> uploading a completed enrollment file.<br>&nbsp;'
      },
      single: {
        headingTitle: 'Enroll single employee',
        headingDesc:
          'Enroll one employee at a time by <br> manually entering their information.<br>&nbsp;'
      },
      mainHeader: 'ENROLL EMPLOYEES',
      flleBrowseHeader: 'ENROLL MULTIPLE EMPLOYEES',
      fileBrowse: {
        uploadHeader: 'Upload enrollment file',
        downloadTemplatelink: 'Download the enrollment file template,',
        downloadTemplateDesc:
          'enter the required information, save the file in .csv format and upload.',
        fileValidationTest:
          'Enrollment file must be in .csv format and size cannot exceed 20 MB',
        dragDropText: 'Drag and drop file or use the \'Browse\' button below',
        fileRenameText:
          'The file is renamed to {companyName}\'s required naming convention.',
        fileType: 'Enrollment',
        dialogData: {
          uploadingFile: {
            title: 'UPLOADING FILE',
            desc: 'This may take a minute...',
            view: 'uploadingFile'
          },
          errorAtUploadFile: {
            desc1: 'is not a valid file name.',
            desc2:
              'Please rename your file to "Enrollment.csv" and upload again.',
            title: '',
            desc: '',
            view: 'errorAtUploadFile'
          }
        }
      }
    },
    verifyPostingFileUpload: {
      uploadHeader: 'Upload Posting Verfication File',
      downloadTemplatelink: '',
      downloadTemplateDesc: 'You can upload .csv file types from your computer.',
      fileValidationTest: 'We accept .csv format',
      dragDropText: 'Drag and drop file or use the \'Browse\' button below',
      fileRenameText: 'The file is renamed to {companyName}\'s required naming convention.',
      fileType: 'PostingVerification',
      dialogData: {
        uploadingFile: {
          title: 'UPLOADING FILE',
          desc: 'This may take a minute...',
          view: 'uploadingFile'
        },
        errorAtUploadFile: {
          desc1: 'is not a valid file name.',
          desc2: 'Please rename your file to "PostingVerification.csv" and upload again.',
          title: '',
          desc: '',
          view: 'errorAtUploadFile'
        }
      }
    },
    dataFileUpload: {
      uploadHeader: 'Upload Data Files',
      downloadTemplatelink: '',
      downloadTemplateDesc: 'You can upload .csv file types from your computer.',
      fileValidationTest: 'We accept .csv format',
      dragDropText: 'Drag and drop file or use the \'Browse\' button below',
      fileRenameText: 'The file is renamed to {companyName}\'s required naming convention.',
      fileType: 'DataFiles',
      dialogData: {
        uploadingFile: {
          title: 'UPLOADING FILE',
          desc: 'This may take a minute...',
          view: 'uploadingFile'
        },
        errorAtUploadFile: {
          desc1: 'is not a valid file name.',
          desc2:
            'Please rename your file to type.csv and upload again.',
          title: '',
          desc: '',
          view: 'errorAtUploadFile'
        }
      }
    },
    documentFile: {
      uploadHeader: 'Upload New Document',
      downloadTemplatelink: '',
      downloadTemplateDesc: 'You can upload a document from your computer.',
      fileValidationTest: '',
      dragDropText: 'Drag and drop file or use the \'Browse\' button below',
      fileRenameText: 'The file is renamed to {companyName}\'s required naming convention.',
      fileType: 'HrPolicy',
      dialogData: {
        uploadingFile: {
          title: 'UPLOADING FILE',
          desc: 'This may take a minute...',
          view: 'uploadingFile'
        },
        errorAtUploadFile: {
          desc1: 'is not a valid file name.',
          desc2:
            'Please rename your file to type.csv and upload again.',
          title: '',
          desc: '',
          view: 'errorAtUploadFile'
        }
      }
    }
  };

  static girdHeaders = {
    dataFiles: ['fileName', 'fileType', 'currentState', 'created', 'isTest'],
    templates: ['name', 'documentVersion', 'download'],
    myDocuments: ['name', 'description', 'created'],
    employees: ['employeeName', 'employeeTascId', 'employeeStatus', 'benefitAccountStatus', 'divisionName', 'eligibilityClassName', 'employeeHireDate'],
    fileSettings: ['Carrier Name', 'Carrier ID', 'Group ID', 'Start Date', 'End Date', 'Med ID'],
    employeeLetters: ['name', 'created', 'currentState', 'firstMailDate', 'lastMailDate', 'sendTo', 'Download']
  };

  static getPath: Model.PathModel = {
    dataType: 'response',
    file: {
      serviceKey: 'file',
      parentType: 'CLIENT',
      reportTypeBase: 'Download',
      getFiles: '/profile/{clientId}/file/search',
      originalDownload: '/profile/{profileId}/file/{fileId}/url?urlType={reportType}',
      errorReportDownload: '/profile/{profileId}/file/{fileId}/report?reportType={reportType}',
      uploadProcess: '/profile/{userId}/file/{fileId}/command/StartToPending',
      upload: '/profile/{userId}/file/{fileId}/url?urlType=upload',
      downloadFile: '/profile/{profileId}/attachment/{attachmentId}',
      viewAttachment: '/profile/{profileId}/supportRequest/{requestId}/attachment/{attachmentId}',
      getSignedUrl: '/profile/{profileId}/fileAttachment/{attachmentId}/geturl?contentDisposition=attachment&contentType={contentType}&fileName={fileName}',
      attachmentSignedUrl: '/profile/{profileId}/fileAttachment/{attachmentId}/geturl?contentDisposition=inline&contentType={contentType}&fileName={fileName}',
      createAttachment: '/profile/{profileId}/attachment/command/{commandName}',
      uploadAttachment: '/profile/{profileId}/fileAttachment/{fileAttachmentId}/puturl',
      cardSignedUrl: '/profile/{cardPackageId}/fileAttachment/{attachmentId}/geturl',
      searchAttachment: '/profile/{profileId}/attachment/search',
      fileSummary: '/profile/{profileId}/file/{fileId}/summary',
      fileProcess: '/profile/{profileId}/file/{fileId}/command/{commandName}',
      fileProcessConfig: '/profile/{clientId}/fileProcessConfig/search'
    },
    profile: {
      serviceKey: 'profile',
      configurationKey: 'profileConfiguration',
      employeeKey: 'parentId',
      employmentList: '/profile/{userId}/configuration/employmentInfo/search',
      documents: '/profile/{profileId}/configuration/document/search',
      personalInformation: '/profile/{individualId}/profileType/individual',
      employmentInfoUpdate: '/profile/{profileId}/configuration/employmentInfo/{employmentHistoryId}/command/{commandName}',
      clientContactUpdate: '/profile/{profileId}/profileType/contact/command/{command}',
      addPersonalInfo: '/profile/{profileId}/profileType/individual/command/{command}',
      individualSearch: '/profile/profileType/individual/search',
      addEmploymentInfo: '/profile/{parentId}/configuration/employmentInfo/{employmentInfoId}/command/{command}',
      getEmployementInfo: '/profile/{parentId}/configuration/employmentInfo/{employmentInfoId}?t={timestamp}',
      getEmployementInfoCountByParentId: '/profile/{parentId}/configuration/employmentInfo/count',
      createDocumentRecord: '/profile/{profileId}/configuration/document/{documentId}/command/{commandName}',
      getDocumentRecord: '/profile/{profileId}/configuration/document/{documentId}',
      acceptsTou: '/profile/{profileId}/configuration/documentAction/undefined/command/{command}',
      getSignedInfo: '/profile/{profileId}/configuration/documentAction/search',
      payrollSchedule: '/profile/{profileId}/configuration/payrollSchedule/search',
      cashAccountBalance: '/profile/{profileId}/profileType/client/balance?clientAccountType=CAB',
      minimumCashAccountBalance: '/profile/{profileId}/configuration/clientserviceprofile',
      publishedDocuments: '/profile/{profileId}/configuration/publishedDocument/search',
      getDependents: '/profile/profileType/dependent/search',
      getDependent: '/profile/{dependentId}/profileType/dependent',
      getCustomization: '/profile/{profileId}/configuration/customization/search',
      clientIdp: '/profile/profileType/activeClientIdp/search',
      addUpdateDependent: '/profile/{dependentId}/profileType/dependent/command/{commandName}',
      fundsTransfer: '/profile/{individualId}/configuration/fundsTransferCriterion/{fundsTransferDataId}/command/{commandName}',
      clientVaccineReport: '/profile/profileType/client/trackingStatus',
      individualVaccineReport: '/profile/profileType/individual/trackingStatus',
      communicationTemplate: '/profile/{profileId}/communicationTemplate/search',
      generateLetterTemplate: '/profile/*/configuration/document/{documentId}/command/{commandName}',
      letterRequest: '/profile/{profileId}/letterRequest/search',
    },
    account: {
      serviceKey: 'account',
      BenifitKey: 'parentId',
      benifiSearchType: 'IN',
      benifitAccounts: '/profile/{clientId}/benefitAccount/search',
      benefitAccountsUpdate: '/profile/{profileId}/benefitAccount/{benefitAccountId}/command/{command}',
      benifitAccountInfo: '/profile/{profileId}/benefitAccount/{benifitAccountId}',
      enrollBenefits: '/profile/{profileId}/benefitAccountEnrollment/search',
      approveBenefits: '/profile/{profileId}/benefitAccount/{benefitAccountId}/command/{commandName}',
      benefitAccountsCount: '/profile/{profileId}/benefitAccount/search',
      entrySummary: '/entrysummary/search',
      entryPostingDates: '/profile/{profileId}/entry/postingDates',
      payrollPostingReport: '/profile/{profileId}/payrollPostingReport?date={date}',
      manualPosting: '/profile/{profileId}/postings/command/{commandName}',
      fundings: '/profile/*/account/achSettlement/search',
      benefitAccountEntryEx: '/profile/{profileId}/benefitAccountEntryEx/search',
      saveBenefitAccountEntry: '/profile/{profileId}/entry/{entryId}/command/{commandName}',
      listAccountEntries: '/profile/{individualId}/benefitAccount/{benefitAccountId}/entry/search',
      electionData: '/profile/{profileId}/election/search',
      payrollSchedule: '/profile/{profileId}/benefitAccount/*/electionSchedule',
      postPayrollSchedule: '/profile/{profileId}/benefitAccount/{benefitAccountId}/electionSchedule',
      benefitAccountBalanceSearch: '/profile/{profileId}/client/{clientId}/benefitAccounts/balances?orderBy=planName&orderDirection=ASC',
      allBenefitsAccount: '/profile/{profileId}/client/{clientId}/benefitAccounts/balances?orderBy=planStartDate',
      electionSearch: '/profile/*/election/search',
      saveElection: '/profile/*/election/{electionId}/command/{command}',
      searchEntryPostings: '/profile/*/benefitAccount/*/entryPosting/search',
      searchEntries: '/profile/{id}/entry/search',
      getTransactionExp: '/profile/{profileId}/benefitAccount/{benefitAccountId}/posting/summary',
      getCABAccountDetails: '/profile/{profileId}/client/{clientId}/accounts/accountType/feature',
      getAssociations: '/profile/{profileId}/dependentBenefitAccess/search',
      saveDependentAssociation: '/profile/{profileId}/dependentBenefitAccess/command/{commandName}',
      getElectionChangeFlag: '/profile/{profileId}/benefitAccount/{accountId}/isElectionChangeValid?accountId={accountId}&effectiveDate={effectiveDate}&electionProfileType={electionProfileType}&amount={amount}'
    },
    configuration: {
      serviceKey: 'configuration',
      benefits: '/profile/{profileId}/configuration/benefitPlan/search',
      benefitPlans: '/profile/{profileId}/configuration/benefitPlan/search',
      paymentPlans: '/profile/{profileId}/configuration/paymentPlan/search',
      fundingSourceAndSchedule: '/profile/{profileId}/configuration/benefitPlan/{benefitPlanId}/fundingSourceAndSchedule/search',
      serviceOffering: '`/profile/{clientId}/configuration/serviceOffering`',
      getOverallAmount: '/profile/{profileId}/configuration/serviceOffering/{serviceOfferingId}/electionLimit/search',
      getOfferingTaxYearLimits: '/profile/{profileId}/configuration/serviceOffering/{serviceOfferingId}/taxYearLimit/search',
      getBenefitPlan: '/profile/{profileId}/configuration/benefitPlan/{benefitPlanId}',
      getOffering: '/profile/{profileId}/configuration/serviceOffering/{serviceOfferingId}',
      requestType: '/profile/{profileId}/configuration/benefitPlan/*/requestType/search',
      paymentTier: '/profile/{profileId}/configuration/benefitPlan/{benefitPlanId}/paymentTier/search'
    },
    dashboard: {
      serviceKey: 'dashboard',
      verifyPostingParticipients: '/profile/{profileId}/participantEntry/search',
      contributionsByClient: '/profile/{profileId}/contributionsByClient/search',
      reimbursment: '/profile/{profileId}/reimbursementsByClient/search',
      employeesByBenefitPlan: '/profile/{profileId}/employeesByBenefitPlan/search',
      transactionActivitySummary: '/profile/{profileId}/transactionActivitySummary/search',
      accountSummaryDeduction: '/profile/{profileId}/entryBenefitAccountSummary/search',
      getClients: '/profile/*/clientsByContact/search',
      employeesByClient: '/profile/{profileId}/employeesByClient/search',
      benefitAccountSummaryByBenefitPlan: '/profile/{profileId}/benefitAccountSummaryByBenefitPlan/search',
      remittanceEntryAggregate: '/profile/{profileId}/remittanceEntryAggregate/search',
      remittanceEntryDetails: '/profile/{profileId}/remittanceEntryDetails/search',
      fundingAndRemittanceEntries: '/profile/{profileId}/fundingAndRemittanceEntry/search',
    },
    support: {
      serviceKey: 'support',
      createSupport: '/profile/{profileId}/supportRequest/create/{commandName}',
      getSupport: '/profile/{profileId}/supportRequest/{supportRequestId}/detail',
      search: '/profile/{profileId}/supportRequest/search',
      supportAttachment: '/profile/{profileId}/supportReqAttachment/command/{commandName}',
      attachmentSearch: '/profile/{profileId}/supportReqAttachment/search',
      addSupportRequestComments: '/profile/{profileId}/supportRequestComments/{fileId}/comments/{commandName}',
      topics: '/profile/{profileId}/topics',
    },
    communication: {
      serviceKey: 'communication',
      alertsURI: '/profile/{profileId}/message/search',
      alertsDetailsURI: '/profile/{profileId}/message/{messageId}',
      alertsMarkAsReadURI: '/profile/{profileId}/message/{messageId}/command/{commandName}',
      communicationRequestURI: '/profile/*/communicationRequest/{communicationRequestId}/command/{commandName}',
      communicationTypesURI: '/profile/*/communicationType/search',
      communicationRegistryURI: '/profile/*/communicationRegistry/search',
    },
    report: {
      serviceKey: 'report',
      reports: '/profile/{profileId}/report/{reportType}/search',
      reportDownload: '/profile/{profileId}/report/getDownloadUrl/{reportOutputFileId}?contentDisposition={contentDisposition}&contentType={contentType}&fileName={fileName}',
      newReport: '/profile/{profileId}/report/{reportType}/command/{commandName}'
    },
    paymentAccount: {
      serviceKey: 'paymentAccount',
    },
  };

  static commands: Model.CommandsModel = {
    StartToPending: 'StartToPending',
    StartToActive: 'StartToActive',
    ActiveToActive: 'ActiveToActive',
    Pending: 'Pending',
    Enrolled: 'Enrolled',
    Removed: 'Removed',
    PendingApprovalTo: 'PendingApprovalTo'
  };

  static popoverData = {
    desc: 'Approve enrollment<br/>before plan effective date.'
  };

  static filterKeys = [
    'fromDate',
    'toDate',
    'class',
    'division',
    'benefitPlan',
    'employmentStatus',
    'subdivision',
    'individualTascId',
    'externalId',
    'employmentRelationship',
  ];

  static cashAccountFilterKey = [
    'transactionDateTime',
    'date',
    'transfer',
    'balaceAdjustment',
    'feepayment',
    'invoicepayment',
    'planfunding',
    'scheduled',
    'published',
    'entered',
    'reversed',
    'rejected',
    'status',
    'transactionType',
    'toDate',
    'fromDate'

  ];

  static cashAccountFilterStorage = [
    'filterApplied',
    'statusApplied',
    'fromDate',
    'toDate',
    'transactiontype-values',
    'status-values'
  ]

  static fundingFilterKeys = [
    'fromDate',
    'toDate'
  ];

  static filterStorage = [
    'division-values',
    'benefitplan-values',
    'paymentplan-values',
    'planYearEnd-values',
    'filterApplied',
    'statusApplied',
    'fromDate',
    'toDate',
    'class-values',
    'subdivision-values',
    'employmentstatus-values',
    'status-values',
    'paymentsource-values',
    'paymentSource',
    'payment-source-selected-item',
    'employmentrelationship-values',
  ];

  static benefitPlanStatusFilter: Array<Model.BenefitPlanStatusFilterData> = [
    {
      name: 'Status',
      data: [
        { id: 1, name: 'Pending Setup' },
        { id: 2, name: 'Cards Ordered' },
        { id: 3, name: 'Active' },
        { id: 4, name: 'Inactive' },
        { id: 5, name: 'Closing' },
        { id: 6, name: 'Closed' },
        { id: 7, name: 'Updates In Progress' },
      ]
    }
  ];

  static benefitRunoutType = {
    EndOfBenefitPlan: 'Plan Year End Date',
    ServiceDate: 'Date of Service'
  };

  static GenderTypesMap = {
    [GenderType.Female]: 'Female',
    [GenderType.Male]: 'Male',
    [GenderType.NonBinary]: 'Non-binary',
    [GenderType.OptionNotListed]: 'Option not listed',
  };

  static PronounsTypesMap = {
    [PronounsType.HeHimHis]: 'He/Him/His',
    [PronounsType.SheHerHers]: 'She/Her/Hers',
    [PronounsType.TheyThemTheirs]: 'They/Them/Theirs',
    [PronounsType.OptionNotListed]: 'Option not listed',
  };

  static PhoneTypes = ['Home', 'Work'];
  static defaultPageSize = 15;

  static terminateDateType: Model.TerminationDateType = {
    DateOfTermination: 'Date of termination',
    EndOfMonthFollowingDateOfTermination:
      'End of the month following the date of termination'
  };

  static terminationSettingForBP: Model.TerminationSetting = {
    LastScheduleDate: 'Last Schedule Date',
    DateOfTermination: 'Date of Termination',
    EndOfMonthOfTerminationDate: 'End of Month of Termination Date',
    EndOfPlanYear: 'End of Plan Year'
  }

  static templateToolTipText = {
    contributions: 'Total new amounts scheduled for deposit into your employees benefit accounts.',
    deduction: 'Total employee deductions',
    funding: 'Total funding amount due from employer on scheduled date.'
  };

  static employmentStatusFilter: Array<Model.EmploymentStatusFilterData> = [
    {
      name: 'Employment Status',
      data: [
        { id: 'Active', name: 'Active' },
        { id: 'Terminated', name: 'Terminated' },
        { id: 'Rehired', name: 'Rehired' },
        { id: 'Retired', name: 'Retired' },
        { id: 'LeaveOfAbsence', name: 'Leave of Absence (LOA)' }
      ]
    }
  ];

  static employmentRelationshipFilter: Array<Model.EmploymentRelationshipFilterData> = [
    {
      name: 'Employment Relationship',
      data: [
        {id: '0', name: 'Employee'},
        {id: '1', name: 'Non-Employee'},
      ]
    }
  ]

  static reportsGirdHeaders = {
    header: ['reportName', 'created', 'frequency', 'status', 'download']
  };

  static netSuiteUserAccessRoles = ['CU1', 'CU5', 'CU6'];

  static fileSettingAccessRoles = ['CU1', 'CU2', 'CU4', 'CU8']

  static validFileTypes = [
    'text/csv',
    'image/jpeg',
    'application/pdf',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  static tieredRecordOrder = ['Each Person', 'Single', 'SinglePlusOne', 'SinglePlusTwo', 'Family'];

  static helpText = {
    terminateEmployees: {
      changeDates: `The following dates are calculated based on the settings in the benefit plan.
       These dates need to be changed only for very few exceptions. By changing the default dates,
       there is a risk your plans might be out of compliance. If you need to change the benefit plans settings,
       contact {companyName}. If you need to make an exception, you can still proceed.`,
      terminationDate: `The employee's last day worked, or if still employed, the final day participating in your benefit plan(s).`,
      individualLastScheduleDate: `The last day on which deductions will be taken for this employee.`,
      clientLastScheduleDate: `The last day you will make a contribution for this employee.`,
      eligibilityEndDate: `Only requests with dates of service on or before this date will be considered.`,
      fullCoveragePaidThroughDate: `Requests with dates of service on or before this date have access to the full contribution amount.`
    }
  }

  static overviewHelpText = {
    contributionText: {
      employeeContribution: `Any client contributions made pre-upgrade are included in your employee contribution total.`,
      employerContribution: `Any participant refunds are included in your employee contribution total.`
    }
  }

  static recordsPerPageOptions = [25, 50, 100];

  static fileHelpText = {
    fileInfoHelpText: [`Click the green check mark to accept these changes.`, `Click the red X to cancel.`, `Processing will be canceled automatically after 12 hours if no action taken.`],
    fileProcessApproveText: `File has been submitted for processing.`,
    fileProcessCancelText: `File processing has been canceled.`,
  }

  static dependentInformationHelpText = {
    addDependentGrowelMessage: `Dependent has been added.`,
    deleteDependentGrowelMessage: `Dependent has been deleted.`,
    editDependentGrowelMessage: `Dependent has been edited.`,
  }

  static DependentAssociationMessage = {
    single: `The Plan coverage tier has been set to Single, therefore, no dependents can be associated to this account. To associate dependents please adjust the coverage tier.`,
    singlePlusOne: `The plan coverage tier has been set to Single+1, therefore, only one dependent can be selected. To associate more dependents please adjust the coverage tier.`,
    singlePlusTwo: `The plan coverage tier has been set to Single+2, therefore, only two dependent can be selected. To associate more dependents please adjust the coverage tier.`,
  }

  static DefaultDatepickerSettings: Model.DateSettings = {
    navigation: 'arrows',
    showWeekNumbers: false
  };

  static EnrollEmployeeHelpText = {
    maxEmployeeContribution: 'Maximum employee contribution cannot exceed',
    maxEmployerContribution: 'Maximum employer contribution cannot exceed',
    maxOverallContribution: 'Sum of maximum employer & employee contribution cannot exceed',
    minEffectiveDate: 'The Benefit Effective date has to be after the Eligibility effective date',
    effectiveDateInfoText: 'Contributions will automatically begin with the next open (not posted) funding date. If you need to include contributions for a funding date that has already been posted, please submit a support request.',
    noPlans: 'Plans are not available for this employee.',
    accountSummaryLabels: {
      planStartDate: 'Plan Start date',
      firstDayForSpending: 'First Day for Spending',
      planEndDate: 'Plan End date',
      lastDayOfspending: 'Last day of spending',
      firstPaycheckDeduction: 'First paycheck deduction',
      paycheckDeductionfrequency: 'Paycheck Deduction frequency'
    },
  }

}

export const RoleOption: Array<Model.UIOption> = [
  {
    displayName: 'Administrator',
    value: UserAccessRoleType.CU1,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'Benefit Plan Manager',
    value: UserAccessRoleType.CU2,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'Employee Manager',
    value: UserAccessRoleType.CU3,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'File Specialist',
    value: UserAccessRoleType.CU4,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'Financial Manager',
    value: UserAccessRoleType.CU5,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'Billing Manager',
    value: UserAccessRoleType.CU6,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'Reports Manager',
    value: UserAccessRoleType.CU7,
    isActive: true,
    isDefault: false
  },
  {
    displayName: 'Reviewer',
    value: UserAccessRoleType.CU8,
    isActive: true,
    isDefault: false
  }
];

export const ExpenditureState = {
  Paid: 'Paid',
  PaidVerified: 'Paid',
  PaidVerificationReq: 'Paid',
  Entered: 'Posted',
  PaidCustomerInputReq: 'Verification Required',
  Denied: 'Declined',
  Returned: 'Reversed'
};

export const UploadDataFileType = [
  {
    name: 'Census',
    value: 'Census'
  },
  {
    name: 'Enrollment',
    value: 'Enrollment'
  },
  {
    name: 'Event',
    value: 'Event'
  },
  {
    name: 'Posting Verification',
    value: 'PostingVerification'
  },
  {
    name: 'Dependent',
    value: 'Dependent'
  },
];

export const EmployerContributionFundsTransferDestination = [
  DestinationType.ClientDirect,
  DestinationType.ClientToPlan
];

export const FileSummaryLabels = [
  {
    statKey: 'numberProcessed',
    statLabel: 'Number of Records Processed'
  },
  {
    statKey: 'numberError',
    statLabel: 'Number of Records with Error'
  },
  {
    statKey: 'numberWarning',
    statLabel: 'Number of Records with Warning'
  },
  {
    statKey: 'numberNewHires',
    statLabel: 'Number of New Hires'
  },
  {
    statKey: 'numberUniqueEmployeeIds',
    statLabel: 'Number of Unique Employee IDs'
  },
  {
    statKey: 'numberTerminations',
    statLabel: 'Number of Terminations'
  },
  {
    statKey: 'numberAddressChanges',
    statLabel: 'Number of Address Changes'
  },
  {
    statKey: 'numberEmailAddressChanges',
    statLabel: 'Number of Email Address Changes'
  },
  {
    statKey: 'numberFirstNameChanges',
    statLabel: 'Number of First Name Changes'
  },
  {
    statKey: 'numberLastNameChanges',
    statLabel: 'Number of Last Name Changes'
  },
  {
    statKey: 'numberHireDateChanges',
    statLabel: 'Number of Hire Date Changes'
  },
  {
    statKey: 'numberDivisionChanges',
    statLabel: 'Number of Division Changes'
  },
  {
    statKey: 'numberSubdivisionChanges',
    statLabel: 'Number of Subdivision Changes'
  },
  {
    statKey: 'numberClassChanges',
    statLabel: 'Number of Class Changes'
  },
  {
    statKey: 'numberNewEnrollments',
    statLabel: 'Number of New Enrollments'
  },
  {
    statKey: 'numberBenefitAccountTerminations',
    statLabel: 'Number of Benefit Account Terminations'
  },
  {
    statKey: 'numberIndividualElectionChanges',
    statLabel: 'Number of Individual Election Changes'
  },
  {
    statKey: 'numberClientElectionChanges',
    statLabel: 'Number of Client Election Changes'
  },
  {
    statKey: 'numberElectionChangeDateMoreThan30Days',
    statLabel: 'Number of Election Change date more than 30 days'
  },
  {
    statKey: 'numberIndividualPostingAmountChanges',
    statLabel: 'Number of Individual Posting Amount Changes'
  },
  {
    statKey: 'numberClientPostingAmountChanges',
    statLabel: 'Number of Client Posting Amount Changes'
  },
  {
    statKey: 'numberPostingAmountChangesToZero',
    statLabel: 'Number of Posting Amount Changes to Zero'
  },
  {
    statKey: 'numberCancelled',
    statLabel: 'Number of Records Cancelled'
  },
  {
    statKey: 'numberCustomElectionsTieredPlan',
    statLabel: 'Number of Custom Elections for Tiered Plans'
  }
]

export const FileProcessStateDisplayTextMapping = {
  ProcessedWithErrors: 'Processed with errors',
  Pending: 'Pending',
  ActionRequired: 'Action required',
  Processing: 'Processing',
  Cancelled: 'Canceled',
  Error: 'Error',
  Processed: 'Processed',
  Cancelling: 'Cancelling'
}

export const CoverageTierDisplayTextMapping = {
  Single: 'Single',
  SinglePlusOne: 'Single +1',
  SinglePlusTwo: 'Single +2',
  Family: 'Family',
  EachPerson: 'Each Person',
  ByMemberUnlimited: ''
}

export enum CoverageType {
  EachPerson = 'EachPerson',
  Group = 'Group'
}

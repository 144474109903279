import dayjs from 'dayjs';
import { NgbDate as NativeNgbDate } from '@ng-bootstrap/ng-bootstrap';

import {
  BenefitAccount,
  BenefitAccountBalance,
  BenefitAccountEnrollment,
  BenefitAccountEntryEx,
  BenefitAccountPostingSummary,
  BenefitElectionSourceAndSchedules,
  ClientAchSettlement,
  Criteria,
  DependentBenefitAccess,
  Election,
  EnrollmentSourceType,
  Entry,
  GroupCriteria,
} from '../uba/account/model';
import { Card } from '../uba/card/model';
import {
  BenefitPlan,
  BenefitPlanCoverageType,
  BenefitPlanFundingSourceAndSchedule,
  BenefitPlanType,
  CarryoverCalculationMethodType,
  ChainType,
  ElectionLimit,
  EligibilityClass,
  EntityBase,
  MatchType,
  ParentType,
} from '../uba/configuration/model';
import { Attachment, ClaimConnexMedCode, FileInfoState } from '../uba/file/model';
import { Address, Client, Contact, Dependent, Email, GenderType, Individual, PhoneType } from '../uba/profile/model';
import {
  DestinationType,
  Division,
  DocumentAction,
  EmploymentInfo, FundsTransferGroupOption,
  PayrollSchedule,
  EligibilityEvent
} from '../uba/profileConfiguration/model';
import { SupportRequest } from '../uba/support/model';
import { Observable } from 'rxjs';
import { CommunicationRegistry, CommunicationTemplate, CommunicationType } from '@models/communication/model';
import { BenefitAccountSummaryByBenefitPlan, FundingAndRemittanceEntry } from '@models/dashboard/model';
import { ReportGroup } from '@app/default/reports/report-landing-page/reports-config';


export namespace Model {
  export interface signedUrlModel {
    url: string;
  }

  export interface OpenEndedPlanEnrollmentDateRange {
    startDate: DateType;
    endDate: DateType;
  }
  export class OpenEndedPlanEnrollmentRanges {
    public firstDate: OpenEndedPlanEnrollmentDateRange;
    public secondDate: OpenEndedPlanEnrollmentDateRange;
  }

  export interface ApproveGridData {
    actionRow: boolean;
    benefitAccounts: Array<BenefitAccountEnrollmentViewModel>;
    detailRow: boolean;
    id: string;
    individualFullName: string;
    individualId: string;
    selected: boolean;
    disabledFlag?: boolean;
    checkedDisabledFlag?: boolean;
    errorFlag?: boolean;
    planName?: string;
    electionScheduleType?: string;
    [key: string]: string | boolean | Array<BenefitAccountEnrollment> | any;
  }

  export interface ApproveCount {
    selecedGridRow: ApproveGridData;
    benefitPlanUpdate: string;
    undoFlag?: boolean;
    selectAll?: boolean;
  }

  export interface GridData<T> {
    data: Array<T>;
    totalCount?: number;
    length?: number;
  }

  export interface Category {
    name?: string;
    data?: Array<CategoryOptions>;
    activeFlag?: boolean;
    applyFlag?: boolean;
    treeCheckList?: boolean;
    selectAllOption?: boolean;
    isRadio?: boolean;
    isDisabled?: boolean;
    isMultiOptionSelect?: boolean
  }

  export interface CategoryOptions {
    name?: string;
    id?: string | number;
    checked?: boolean;
    fromDate?: string;
    toDate?: string;
    length?: number;
    type?: string;
    bankAccounts?: Array<any>;
    isVisible?: boolean;
    ids?: string[];
  }

  export interface CurrentSelectionStatus {
    [key: string]: Array<ApproveGridData> | any;
  }

  export interface FilterOption<T> {
    name?: string;
    selectedOptions?: T;
    status?: Array<boolean>;
    bankAccountIndex?: number;
  }

  export interface FilterFlag {
    active?: boolean;
    clearFilter?: boolean;
    index?: number;
  }

  export interface MapResult {
    name: string;
    id: string | number;
  }

  export interface FilterKeys {
    [key: string]: string;
    fromDate?: string;
    toDate?: string;
  }

  export interface PayLoad {
    fromDate?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    toDate?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    class?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    division?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    benefitPlan?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    employmentStatus?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    subdivision?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    externalId?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    employmentRelationship?: {
      key: string;
      value: string;
      matchType: MatchType;
    };
    [key: string]: {
      key: string;
      value: string;
      matchType?: MatchType;
    };
  }

  export interface StringArray {
    [key: string]: string[] | any;
  }

  export interface BenefitAccountEnrollmentViewModel extends BenefitAccountEnrollment {
    name?: string;
    actionRow?: boolean;
    detailRow?: boolean;
    electionScheduleType?: string;
    selected?: boolean;
    [key: string]: string | any;
  }

  export interface DependentSelection {
    selectedDependents?: SelectedDependents;
    isDepSelectionValid?: boolean;
    isDepSelectionDirty?: boolean;
    isAddOrUpdate?: boolean;
    eligibilityEndDate?: string;
    isDependentAddedDuringEnrollment?: boolean;
  }

  export interface SelectedDependents {
    addAssociations: Model.DependentEffectiveDateMapping[];
    removeAssociations: Model.DependentEffectiveDateMapping[];
    updateAssociations: Model.DependentEffectiveDateMapping[];
  }

  export interface DependentEffectiveDateMapping {
    dependent?: Dependent;
    eligibilityStartDate?: string;
    eligibilityEndDate?: string;
    association?: DependentBenefitAccess;
  }

  export interface DependentAssociationViewModel extends DependentBenefitAccess {
    selected?: boolean;
  }

  export interface DependentAccountAssociation {
    accounts: BenefitAccountBalance[];
    associations: DependentBenefitAccess[];
    indDependents?: Dependent[]
  }

  export interface AccountAssociation {
    accounts: BenefitAccountBalance[];
    associations: DependentBenefitAccess[];
  }

  export interface ContactResponseModel {
    body: Contact[];
    headers?: ContactResponseHeaders;
  }

  export interface ContactResponseHeaders {
    get(name: string): string | null;
  }

  export interface EmployeeAccounts {
    employees: Model.ManageGridData[];
    hasMore?: boolean;
    totalCount?: number;
    benefitAccounts: BenefitAccount[];
    columnsStatus?: Division | Client;
    [key: string]: Model.EmployeeStatus | Model.ManageGridData[] | number | boolean | BenefitAccount[] | Division | Client;
  }

  export interface ManageGridData {
    benefitPlans?: Array<BenefitPlan | string>;
    className: string;
    clientExternalId: string;
    clientId: string;
    clientName: string;
    clientPayrollId: string;
    detailRow: boolean;
    divisionId: string;
    divisionName: string;
    eligibilityClassId: number;
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    phoneType: PhoneType;
    hireDate: string;
    individualId: string;
    individualName?: string;
    menu?: boolean;
    hasInvestmentAccount?: boolean;
    [key: string]: string | number | boolean | Array<BenefitPlan> | any;
    eligibilityEvents: Observable<EligibilityEvent[]>
  }

  export interface ClassesList {
    classId?: number;
    name?: string;
    id?: string;
  }

  export interface UserProfileModel {
    tascId?: string;
    ein?: string;
    clientName?: string;
    filingStatus?: string;
    address?: Address[];
    contacts?: Array<Contact>;
    cancellationDate?: string;
  }

  export interface UserPhonenumberModel {
    phone?: string;
    phoneType?: string;
  }

  export interface EmployeeStatus {
    columnsStatus?: Division | Client;
    [key: string]: any;
  }

  export interface AlertRequestParams {
    requestPayload: SearchCriteria;
    searchParams: Model.SearchParams;
  }

  export interface SearchParams {
    take?: number;
    skip?: number;
    orderBy?: string;
    orderDirection?: string;
    employeeByClientGrouping?: string;
    transactionType?: string;
    reimbursementTimePeriodType?: string;
    groupBy?: string;
    participantEntryGrouping?: string;
    effectiveDate?: string;
    viewName?: string;
  }

  export interface ApproveList {
    pendingEmployees: ApproveGridData[];
    totalCount: number;
    benefitFilter: EmployeeTransactionFilteroptions[];
    employerColumnShow: boolean;
    [key: string]: any;
  }

  export interface EmployeeTransactionFilteroptions {
    id?: string;
    name: string;
  }

  export interface EmpFilterResponse {
    employeeStatus?: Category;
    divisions?: Division[];
    eligibilityClass?: EmpClasses[];
  }

  export interface EmpClasses {
    name: string;
    id: number;
    eligibilityClasses: EligibilityClass[];
  }

  export interface EmployeeTransactionFilterData {
    name: string;
    data: Array<EmployeeTransactionFilteroptions>;
  }

  export interface ApproveGridResponseData {
    totalCount: number;
    data: Array<ApproveGridData> | Array<MockData>;
  }

  export interface MockData extends EntityBase {
    multiplePlanDetails?: any[];
    benefitPlans?: any[];
    className: string;
    clientExternalId: string;
    clientName: string;
    clientPayrollId: string;
    detailRow: boolean;
    divisionId: string;
    divisionName: string;
    eligibilityClassId: number;
    employeeId: string;
    externalId: string;
    hireDate: string;
    individualId: string;
    individualName: string;
    subdivisionId: string;
    employeeCreated?: string;
    planName?: string;
    numberOfBenefitAccounts?: number;
    clientContributionAmount?: number;
    participantDeductionAmount?: number;
  }

  export interface DependentsAssociationViewModel {
    id: string;
    dependentName: string;
    benefitAccounts: string[];
    effectiveDate: string[];
    eligibilityEndDate: string[];
  }

  export interface AccountAssociationsViewModel {
    account: BenefitAccount;
    dependents: Dependent[];
    associations: DependentBenefitAccess[];
    dependentNames: string[];
    effectiveDates: string[];
    eligibilityEndDates: string[];
    showAccount: boolean;
    showEditEffectiveDateRow: boolean;
  }

  export interface AssociationsAndAccounts {
    accounts: BenefitAccount[];
    associations: DependentBenefitAccess[];
  }

  export interface DependentInformation extends AssociationsAndAccounts {
    dependents: Dependent[];
  }

  export interface DependentAssociationMapping {
    association: DependentBenefitAccess;
    benefitAccount: BenefitAccount;
  }

  export interface AccountAssociationMapping {
    association: DependentBenefitAccess;
    dependent: Dependent;
  }

  export interface AddEditAssociationDialogData {
    dependents: Dependent[];
    accounts: BenefitAccount[];
    associations: DependentBenefitAccess[];
    plans: BenefitPlan[];
  }

  export type SearchCriteria = Criteria[];

  export interface QueryParams {
    profileId?: string | boolean;
    transactionType?: string;
    clientId?: string;
    id?: string;
    reportType?: string;
    userId?: string;
    benefitPlanId?: string;
    serviceOfferingId?: string;
    individualId?: string;
    benefitAccountId?: string;
  }

  export interface ProfileConfiguration {
    serviceKey?: string;
    configurationKey?: string;
    employeeKey?: string;
    employmentList?: string;
    documents?: string;
    personalInformation?: string;
    individual?: string;
    employmentInfoUpdate?: string;
    userSearch?: string;
    clientContactUpdate?: string;
    addPersonalInfo?: string;
    individualSearch?: string;
    addEmployementInfo?: string;
    getEmployementInfo?: string;
    createDocumentRecord?: string;
    acceptsTou?: string;
    getSignedInfo?: string;
  }

  export interface FileConfiguration {
    serviceKey?: string;
    parentType?: string;
    reportTypeBase?: string;
    getFiles?: string;
    originalDownload?: string;
    errorReportDownload?: string;
    uploadProcess?: string;
    upload?: string;
    downloadFile?: string;
    getSignedUrl?: string;
    attachmentSignedUrl?: string;
    createAttachment?: string;
    uploadAttachment?: string;
    cardSignedUrl?: string;
    searchAttachment?: string;
  }

  export interface SearchResults<T> {
    data: T;
    hasMore: boolean;
    totalCount?: number;
  }

  export interface ConfigurationPath {
    serviceKey?: string;
    benefits?: string;
    benefitPlans?: string;
    paymentPlans?: string;
    fundingSourceAndSchedule?: string;
    serviceOffering?: string;
  }

  export interface BenefitPlanData extends BenefitPlan {
    planId?: string;
    planName?: string;
    eligibilityEndDate?: string;
    payrollLastDate?: string;
    // id?: string;
    // eligibilityEndDateType?: string;
    // externalName?: string;
    terminationDate?: string;
    // planEndDate?: string;
    errorFlag?: boolean;
    payrolltoggle?: boolean;
    eligibilitytoggle?: boolean;
    eligibilityType?: string;
    enrollmentStatus?: string;
    // cashAccountMinimumBalance?: number;
    payroll?: PayrollSchedule;
    payrollDates?: string[];
    paycheckDeductionFrequency?: string;
    isOnlyUserInitiatedSchedules?: boolean;
    noPayrollAndOnlyUserInitiated?: boolean;
  }

  export interface FundingData {
    fundingPostingMethod: string;
    contributionPostingMethod: string;
    deductionPostingMethod: string;
    [key: string]: string | any;
  }

  export interface BenefitFundingSource {
    accounts?: Model.BenefitAccountView[];
    benefitPlans?: BenefitPlan[];
    fundingSourceAndSchedule?: BenefitPlanFundingSourceAndSchedule[];
  }

  export interface BenefitAccountView extends BenefitAccount {
    fundingSource?: Array<string>;
  }

  export interface BenefitAcccountsWithPlans {
    benefitPlans: BenefitPlan[];
    benefitAccounts: BenefitAccount[];
  }

  export interface FileInfo {
    id: any;
    parentId: string;
    fileName: string;
    fileType: string;
    isTest: boolean;
    currentState: string;
    parentType: string;
    version: number;
  }

  export interface Command {
    id: any;
    version: number;
    producerId: string;
    eventCorrelationId: any;
    type: string;
    data: FileInfo;
    created: string;
    createdBy: string;
    createdById: string;
  }

  export interface ExistingEmploymentInfo {
    employmentInfo?: EmploymentInfo;
    personalInfo?: Individual;
  }

  export interface EmploymentInfoViewModel extends EmploymentInfo {
    employmentStatus?: string;
  }

  export interface TransactionActivitySummary {
    individualId?: string;
    benefitAccountId?: string;
    planId?: string;
    planName?: string;
    externalAccountId?: string;
    status?: string;
    transactionType?: string;
    totalAmount?: number;
  }

  export enum TransactionActivityEndpointSource {
    Account = 'Account',
    Contribution = 'Contribution',
    Expenditure = 'Expenditure',
    PendingEntry = 'PendingEntry',
    PendingRequest = 'PendingRequest',
    PendingTransfer = 'PendingTransfer',
  }

  export interface TransactionsList {
    clientId: string;
    planId: string;
    planName: string;
    planDescription?: string;
    planStartDate: string;
    planEndDate?: string;
    hireDate: string;
    benefitEffectiveDate: string;
    eligibilityEndDate?: string;
    clientLastScheduleDate?: string;
    individualLastScheduleDate?: string;
    requestTypes?: RequestTypes;
    soaAccountId?: string;
    balance?: number;
    carryoverDeclined: boolean;
    approvedBy?: string;
    approvedDate?: string;
    payrollScheduleId?: string;
    excludeFromBilling: boolean;
    cashAccountMinimumBalance?: number;
    enrollmentSource?: EnrollmentSourceType;
    clientScheduleFirstDate?: string;
    individualScheduleFirstDate?: string;
    scheduleEndDate?: string;
    lastDayToSubmitExpenses?: string;
    reasonToHoldDisbursements?: string;
    fullCoveragePaidThroughDate?: string;
    transactionType?: string;
    entryTransactionDateTime?: string;
    requestPaymentPaymentDateTime?: string;
    carryOverLabel?: string;
  }

  export interface RequestTypes {
    expenseType?: string;
    serviceType?: string;
    limit?: number;
  }

  export interface Headers {
    headers: any;
    [key: string]: any;
  }

  export interface EnrollEmployeeBenefitPlanViewModel extends BenefitPlan {
    benefitEffectiveDate?: string;
    showEmployer?: boolean;
    showEmployee?: boolean;
    employerLimitMaximum?: number; // employer max
    clientElection?: number; // patch value for employer contribution in case of edit
    individualElection?: number; // patch value for employee contribution in case of edit
    ssn?: string;
    overall?: number; // overall max
    enrollmentStatus?: string; // pending, active, skipped, success
    service_icons?: Array<string>; // array of service string for icons
    isNew?: boolean; // true if new enrollment
    payrollDates?: string[];
    isLastPlan?: boolean;
    isSuccessfullyEnrollInFlow?: boolean;
    disableInFlowAfterSuccess?: boolean;
    payroll?: PayrollSchedule;
    noPayrollAndOnlyUserInitiated?: boolean;
    paycheckDeductionFrequency?: string;
    payrollsMatchingWithEmployee?: PayrollSchedule[];
    fundingSourcesAndSchedules?: BenefitPlanFundingSourceAndSchedule[];
    isOpenEnded?: boolean;
    insideEnrollmentPeriod?: boolean;
    payrollSchedules?: PayrollSchedule[];
    [key: string]: string | number | boolean | Array<string> | Array<number> | any;
  }

  export interface BenefitPlanHSAParticipantDirect {
    benefitNonHSAPLans?: BenefitPlan[];
    benefitNonParticipantDirectPlans?: BenefitPlan[];
    benefitNonParticipantDirectFUndingSourcePlans?: BenefitPlan[];
    plans?: BenefitPlan[];
    deductionData?: Model.EntryBenefitAccountSummary[];
  }

  export interface EntryBenefitAccountSummary {
    individualId?: string;
    benefitAccountId?: string;
    benefitPlanId?: string;
    planName?: string;
    entryType?: string;
    entryCurrentState?: string;
    entryFundingSource?: string;
    entryAmountSum?: number;
  }

  export interface BenefitAccountPostingSummaryViewModel extends BenefitAccountPostingSummary {
    id?: string;
  }

  export interface AccountElection {
    elections: Election[];
    accounts: BenefitAccountBalance[];
    deductionData: Model.EntryBenefitAccountSummary[];
    benefitAccountNonParticipantDirect?: BenefitAccountBalance[];
    contributionExpData?: Model.BenefitAccountPostingSummaryViewModel[];
    plans?: Model.BenefitPlanHSAParticipantDirect;
    payrollSchedules?: PayrollSchedule[];
  }

  export interface IndividualAndBenefitsAccounts {
    accounts?: BenefitAccount[];
    individual?: Individual;
  }

  export interface ElectionLimitSearchCriteria {
    offeringId: string;
    benefitPlanEffectiveDate?: string;
    isCurrentlyEffective?: boolean;
    latestLimitOnly?: boolean;
    sortColumn?: string;
    sortDirection?: string;
    take?: number;
  }

  export interface SummaryTableData {
    plan?: BenefitPlan;
    electionLimit?: ElectionLimit[];
    accountOffering?: ElectionLimitViewModel;
    accountEntries?: Entry[];
    benefitElectionSourceAndSchedules?: BenefitElectionSourceAndSchedules[];
    benefitPlanFundingSourceAndSchedule?: BenefitPlanFundingSourceAndSchedule[];
    electionChangeValidation?: Model.ElectionChangeValidation;
  }

  export interface ElectionLimitViewModel extends ElectionLimit {
    requireQualifyingEvent?: boolean;
  }

  export interface ElectionChangeValidation {
    benefitAccountId?: string;
    isElectionChangeValid: boolean;
    warningMessage?: string;
    errorMessage?: string;
  }

  export interface BenefitAccountElectionBalance {
    requireQualifyingEvent: boolean;
    account: BenefitAccountBalanceViewModel;
    individualElection: Election;
    clientElection: Election;
    prorationPercentage: number;
    pendingIndividualElection?: Election[];
    pendingClientElection?: Election[];
    benefitPlan?: BenefitPlan;
    electionMax?: number;
    electionOverallLimit?: number;
    electionProfileLimit?: number;
    electionMin?: number;
    scheduleChangeEffectiveDate?: string[];
    amountsUpdated?: boolean;
    accountOffering?: ElectionLimit[];
    accountEntries?: Entry[];
    addDisbursableDate?: boolean;
    editDisbursableDate?: boolean;
    isDateInvalid?: boolean;
    disbursableDate?: string;
    resetDisbursableDate?: string;
    isDisabled?: boolean;
    isDisbursableDateAvailable?: boolean;
    clientElectionProfileType?: string;
    individualElectionProfileType?: string;
    clientElectionEffectiveDate?: string;
    individualElectionEffectiveDate?: string;
    isOpenEnded?: boolean;
    futurePayrollSchedule?: PayrollSchedule;
    isFutureIndividualElection?: boolean;
    isFutureClientElection?: boolean;
    isReadonly?: boolean;
  }

  export interface BenefitAccountBalanceViewModel extends BenefitAccountBalance {
    isHSAFundingWithParticipantDirect?: boolean;
    benefitPlanState?: boolean;
    benefitPlan?: BenefitPlan;
    balance?: number;
    isParticipantDirect?: boolean;
    isHiddenHSA?: boolean;
  }

  export interface EmployeesByClient {
    clientId?: string;
    individualId?: string;
    individualTascId?: string;
    individualFirstName?: string;
    individualLastName?: string;
    individualFullName?: string;
    individualCreated?: string;
    individualPrimaryEmail?: string;
    individualStatus?: string;
    individualIdVerification?: string;
    employeeId?: string;
    employeeCreated?: string;
    employeeStatus?: EmploymentStatus;
    employeeEmployeeId?: string;
    employeeHireDate?: string;
    employeeDivisionId?: string;
    employeeSubdivisionId?: string;
    employeeEligibilityClassId?: string;
    eligibilityClassName?: string;
    employeePayrollScheduleName?: string;
    employeeClientPayrollId?: string;
    employeeTerminationDate?: string;
    employeeRetired?: string;
    employeeAverageHoursWorked?: string;
    divisionName?: string;
    divisionSubdivisions?: string;
    planId?: string;
    planName?: string;
    benefitAccountId?: string;
    soaAccountId?: string;
    benefitEffectiveDate?: string;
    clientContributionAmount?: number;
    participantDeductionAmount?: number;
    numberOfBenefitAccounts?: number;
    hasInvestmentAccount?: boolean;
    isNonEmployee?: boolean;
    individualConvertedFromDependentId?: string;
}

  export enum EmploymentStatus {
    Active = 'Active',
    Terminated = 'Terminated',
    Rehired = 'Rehired',
    Retired = 'Retired',
    LeaveOfAbsence = 'LeaveOfAbsence'
  }

  export interface SharedEmployeeInfo {
    id?: string;
    individualId?: string;
    externalId?: string;
    individualName?: string;
    clientPayrollId?: string;
    parentId?: string;
    eligibilityClassId?: string;
    hireDate?: string;
    employmentStatus?: EmploymentStatus;
  }

  export interface IndividualDependentInfo {
    dependents?: Dependent[];
    dependentsCards?: Card[];
    individualCards?: Card[];
    individualInfo?: Individual;
    noCardsAvailable?: boolean;
  }

  export interface OnDemandFundsData {
    benefitAccounts: BenefitAccount[];
    individual: Individual;
    fundingSourcesAndSchedules: BenefitPlanFundingSourceAndSchedule[];
  }

  export interface EmployeeAndEnrollmentData {
    accounts?: BenefitAccount[];
    individual?: Individual;
    payrollSchedules?: PayrollSchedule[];
    requestTypes?: BenefitPlanRequestType[];
    eligiblePlans?: Model.EnrollEmployeeBenefitPlanViewModel[];
    showNoPayrollIdError?: boolean;
  }

  export enum ExpenditureEntryTypeTransaction {
    PayrollFundingPlanRollover = 'PayrollFundingPlanRollover',
    PodFundingPlanRollover = 'PodFundingPlanRollover',
    TakeoverDisbursement = 'TakeoverDisbursement',
    BenefitAccountBalanceAdjustment = 'BenefitAccountBalanceAdjustment',
    CardForced = 'CardForced',
    PreprocessedDisbursementApproved = 'PreprocessedDisbursementApproved',
    DisbursementRejection = 'DisbursementRejection',
    PaymentAdjustment = 'PaymentAdjustment'
  }

  export interface RemoveEmployeeResponse {
    fundingAndSchedules?: BenefitPlanFundingSourceAndSchedule[][];
    benefitAccounts: BenefitAccount[];
  }

  export interface InfiniteAction {
    loadingFlag: boolean;
    data: Array<number>;
    [key: string]: boolean | Array<number> | any;
  }

  export interface DataGroup {
    id: string | number;
    name: string;
  }

  export interface FileUploadData {
    multiple: FileTitle;
    single: FileTitle;
    mainHeader: string;
    flleBrowseHeader: string;
    fileBrowse: BrowseView;
  }

  export interface FileTitle {
    headingTitle: string;
    headingDesc: string;
  }

  export interface BrowseView {
    uploadHeader: string;
    downloadTemplatelink: string;
    downloadTemplateDesc: string;
    fileValidationTest: string;
    dragDropText: string;
    fileRenameText: string;
    fileType: string;
    dialogData: DialogData;
  }

  export interface DialogData {
    uploadingFile?: UploadingFileText;
    errorAtUploadFile?: ErrorAtUploadFileText;
    flag?: boolean;
    view?: string;
    hasCloseBtn?: boolean;
    templateName?: any;

    // This interface was defined in two places with separate sets of properties. The following are from the second definition (originally settings.d.ts).
    title?: string;
    desc?: string;
    userdata?: any;
    primaryButton?: string;
    secondaryButton?: string;
    editRef?: BenefitPlanRequestType;
    message?: string;
  }

  export interface BenefitPlanRequestType extends EntityBase {
    requestTypeSelections?: RequestTypeSelections[];
    requestTypes?: RequestTypeSelections[];
  }

  export interface BenefitPlanQueryEffectiveDateGrouping {
    effectiveDate: string;
    benefitPlanIds: string[];
  }

  export interface UploadingFileText {
    title: string;
    desc: string;
    view: string;
  }

  export interface ErrorAtUploadFileText {
    desc1: string;
    desc2: string;
    title: string;
    desc: string;
    view: string;
  }

  export interface UploadFileDialogData extends DialogData {
    fileName: string;
    desc1: string;
    desc2: string;
  }

  export interface FileData {
    renameFile: string;
    fileType: string;
    isTest: boolean;
  }


  export interface CardDetailsView {
    cardHolderName?: string;
    cardNumber?: string;
    cardState?: string;
    cardActiveTill?: string;
    cardId?: string;
  }

  export interface RequestReasonDrpDown {
    value: string;
    label: string;
  }

  export interface CustomizationLogo {
    url: string;
    isCustomBranding?: boolean;
  }

  export interface ServiceParams {
    serviceUrl: string;
    isPagination: boolean;
    requiredNumberOfRecords: number;
  }

  export interface EventCategory {
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: number;
    eventNameMapping?: {
      [key: string]: string;
    };
  }

  export interface EventCategories {
    SignUp: EventCategory;
    SignInSuccess: EventCategory;
    SignInFail: EventCategory;
    PasswordReset: EventCategory;
    PersonalInfoEdit: EventCategory;
    EmploymentInfoEdit: EventCategory;
    SelectClient: EventCategory;
    AddSingleEmployee: EventCategory;
    AddMultipleEmployees: EventCategory;
    OfferingPriceAdjustment: EventCategory;
    EmployeeAccountSummary: EventCategory;
    TascCardReissue: EventCategory;
    TascCardRequest: EventCategory;
    ApproveEnrollment: EventCategory;
    RejectEnrollment: EventCategory;
    PVRAmountEdit: EventCategory;
    PVRDownload: EventCategory;
    PVRUpload: EventCategory;
    PVRPerPageCount: EventCategory;
    ManualPosting: EventCategory;
    PastPosting: EventCategory;
    ContactUsCategorySubcategory: EventCategory;
    AddDependent: EventCategory;
    DeleteDependent: EventCategory;
    EditDependent: EventCategory;
    RemoveEmployee: EventCategory;
    DataFileUpload: EventCategory;
    DataFileDownload: EventCategory;
    MyDocumentsUpload: EventCategory;
    MyDocumentsDownload: EventCategory;
    TemplatesDownload: EventCategory;
    NetSuiteRedirect: EventCategory;
    ManageUsersEditAccessExisting: EventCategory;
    ManageUsersDelete: EventCategory;
    ManageUsersAdd: EventCategory;
    TwoFAOn: EventCategory;
    TwoFAOff: EventCategory;
    ChangePassword: EventCategory;
    ChangeMobileNumber: EventCategory;
    SubmitSupportRequest: EventCategory;
    ReportRequest: EventCategory;
    ReportDownload: EventCategory;
    FundingTransactionsPerPageCount: EventCategory;
    OpenFaq: EventCategory;
    VideoOpen: EventCategory;
    UserGuideOpen: EventCategory;
    ViewEmployeePerPageCount: EventCategory;
    EnrollSingleEmployee: EventCategory;
    EnrollDependents: EventCategory;
    AddDependentFromEnrollment: EventCategory;
    EnrollMultipeEmployees: EventCategory;
    AddFundsToBenfitAccount: EventCategory;
    AddDisbursableDateToBenfitAccount: EventCategory;
    EditDisbursableDateOfBenfitAccount: EventCategory;
    FileSettingRedirect: EventCategory;
    AddDependentAssociation: EventCategory;
    EditDependentAssociation: EventCategory;
    EditDependentEffectiveDate: EventCategory;
    EmployeeInvestments: EventCategory;
    ReplySupportRequest: EventCategory;
  }

  export interface AddFundConfirmAction {
    submit?: boolean;
    individual?: Model.EmployeesByClient;
    benefitAccount?: BenefitAccountViewModel;
    amount?: number;
    eligibleAccounts?: FundsTransferGroupOption[];
    noEligibleAccounts?: boolean;
    fundsTransfer?: FundsTransferGroupOption
  }

  export interface BenefitAccountViewModel extends BenefitAccount {
    fundsTransferDestinationType?: DestinationType;
  }

  export interface ModalSettings {
    target: string;
    title: string;
    dismiss?: boolean;
    tabindex?: number;
  }

  export interface FilePayload {
    fileInfo: FileInfo;
    command: any;
  }

  export interface DropdownData<T> {
    label: string;
    option: Array<T>;
    selected?: number;
  }

  export interface BenefitPlanStatusFilterData {
    name: string;
    data: Array<BenefitPlanStatusFilteroptions>;
    applyFlag?: boolean;
  }

  export interface BenefitPlanStatusFilteroptions {
    id: number;
    name: string;
  }

  export interface StatusFilterOptions {
    name: string;
    value: Array<string>;
  }

  export interface DataGroup {
    id: string | number;
    name: string;
  }

  export interface UploadFileForm {
    file: File;
    fileDirty: boolean;
    fileType: string;
    isTest: boolean;
    renameFile: string;
  }

  export interface UploadFileTypes {
    name: string;
    value: string;
  }

  export interface AlertData {
    alertId: string;
    alertMessage: string;
    alertDateTime: string;
    unread: boolean;
    isMessageLoaded: boolean;
    isMessageShow: boolean;
    alertObject: Model.AlertObject;
    messageContent: string;
  }

  export interface AlertObject {
    activityId?: string;
    created?: string;
    createdBy?: string;
    createdById?: string;
    currentState?: string;
    distributionMethodType?: string;
    formattedFileId?: string;
    formattedMessageText?: string;
    id?: string;
    lastTransition?: string;
    messageTrackingId?: string;
    parentId?: string;
    parentType?: string;
    recipientId?: string;
    recipientName?: string;
    recipientType?: string;
    subject?: string;
    updated?: string;
    updatedBy?: string;
    updatedById?: string;
    version?: number;
    messageContent?: string;
    deliveryPayload?: {};
    deliveryStatuses?: Array<AlertStatus>;
    messageContentInfo?: {};
  }

  export interface AlertStatus {
    status: string;
    created: string;
  }

  export interface MarkAsRead {
    data: Model.AlertObject;
    createdById: string;
    createdBy: string;
    created: string;
    producerId: string;
    id: string;
    type: string;
    eventCorrelationId: string;
  }

  export interface FileFormData {
    file: string;
    fileDescription: string;
    testFile: boolean;
  }

  export interface FileSelectEvent {
    srcElement?: {
      files: File[]
    };
  }

  export interface Columns {
    id?: string;
    order?: string;
    name?: string;
    sortingOrder?: string;
    active?: boolean;
    class?: string;
  }

  export interface DialogSpinner {
    mode: ProgressSpinnerMode;
    value: number;
  }

  export interface URLInfo {
    url?: string;
  }

  export interface CardImage {
    attachmentFlag: boolean;
    signedUrlResponse: {};
    [key: string]: string | any;
  }

  export interface ErrorModel {
    stack?: string;
    code?: string;
  }

  export interface ErrorCodes {
    NotAuthorizedException: string;
    UserNotFoundException: string;
    UnknownError: string;
    CodeMismatchException: string;
    ExpiredCodeException: string;
    UserNotConfirmedException: string;
    fileUploadSuccess: string;
    dataFileUploadSuccess: string;
    fileUploadError: string;
    passwordChangeSuccess: string;
    phoneNumberChangeSuccess: string;
    codeSentSuccess: string;
    wrongPasswordError: string;
    invalidCredential: string;
    samePasswordError: string;
    singleEnrolled: string;
    allEnrolled: string;
    singleRemoved: string;
    allRemoved: string;
    benefitMaximumLimit: string;
    benefitMinimumLimit: string;
    blankValue: string;
    ErrorFromApi: string;
    postingSuccess: string;
    addExistEmployee: string;
    addEmployee: string;
    editEmployee: string;
    deleteSuccess: string;
    deleteError: string;
    noRecords: string;
    invalidVerificationCode: string;
    touMissingError: string;
    verifyBenefitMaximumLimit: string;
    removeEmployeeServiceFailure: string;
    apiFailure: string;
    disableToggleMessage: string;
    backdatedElectionChange: string;
    fileCountErrorMessage: string;
    fileTypeSizeErrorMessage: string;
    letterDownloadFailure: string;
    [key: string]: string;
  }

  export interface CommandsModel {
    StartToPending: string;
    StartToActive: string;
    ActiveToActive: string;
    Pending: string;
    Enrolled: string;
    Removed: string;
    PendingApprovalTo: string;
    [key: string]: string;
  }

  export interface DialogDataModel {
    changePassword: {
      title: string;
      desc: string;
      view: string;
    };
    editMobileNumber: {
      title: string;
      desc: string;
      view: string;
    };
    infoSignInSecurity: {
      title: string;
      desc: string;
    };
    payrollSchedule: {
      title: string;
      desc: string;
    };
    infoDataFiles: {
      title: string;
      desc: string;
    };
    benefitPlan: {
      title: string;
      desc: string;
    };
    templates: {
      title: string;
      desc: string;
    };
    verifyPosting: {
      title: string;
      desc: string;
    };
    coveragePeriod: {
      title: string;
      desc: string;
    };
    addAddress: {
      title: string;
      desc: string;
      primaryButton: string;
    };
    postingSummary: {
      title: string;
      desc: string;
    };
    tascCard: {
      title: string;
      desc: string;
    };
    addContact: {
      title: string;
      desc: string;
      primaryButton: string;
    };
    editContact: {
      title: string;
      desc: string;
      primaryButton: string;
    };
    postManually: {
      title: string;
      desc: string;
    };
    fundingTransactionsInfo: {
      title: string;
      desc: string;
    };
    bankAccountInfo: {
      title: string;
      desc: string;
    };
    myDocuments: {
      title: string;
      desc: string;
    };
    divisions: {
      title: string;
      desc: string;
    };
    classes: {
      title: string;
      desc: string;
    };
    contributions: {
      title: string;
      desc: string;
    };
    reimbursement: {
      title: string;
      desc: string;
    };
    expenditures: {
      title: string;
      desc: string;
    };
    [key: string]: {
      title: string;
      desc: string;
      view?: string;
      primaryButton?: string;
      positionTop?: string;
      width?: string;
    };
  }

  export interface DialogReturnType {
    reload: boolean;
    success: boolean;
  }

  export interface TerminationDateType {
    DateOfTermination: string;
    EndOfMonthFollowingDateOfTermination: string;
    [key: string]: string;
  }

  export interface TerminationSetting {
    LastScheduleDate: string;
    DateOfTermination: string;
    EndOfMonthOfTerminationDate: string;
    EndOfPlanYear: string;
    [key: string]: string;
  }

  export interface PathModel {
    dataType: string;
    file: FileModel;
    profile: ProfileModel;
    account: AccountModel;
    configuration: ConfigurationModel;
    dashboard: DashboardModel;
    support: SupportModel;
    communication: CommunicationModel;
    report: ReportModel;
    paymentAccount: PaymentAccountModel;
    [key: string]: string | FileModel | ProfileModel | AccountModel | ConfigurationModel | DashboardModel | SupportModel | CommunicationModel | ReportModel | PaymentAccountModel;

  }

  export interface FileModel {
    serviceKey: string;
    parentType: string;
    reportTypeBase: string;
    getFiles: string;
    originalDownload: string;
    errorReportDownload: string;
    uploadProcess: string;
    upload: string;
    downloadFile: string;
    viewAttachment: string;
    getSignedUrl: string;
    attachmentSignedUrl: string;
    createAttachment: string;
    uploadAttachment: string;
    cardSignedUrl: string;
    searchAttachment: string;
    fileSummary: string;
    fileProcess: string;
    fileProcessConfig: string;
    [key: string]: string;
  }

  export interface ProfileModel {
    serviceKey: string;
    configurationKey: string;
    employeeKey: string;
    employmentList: string;
    documents: string;
    personalInformation: string;
    employmentInfoUpdate: string;
    clientContactUpdate: string;
    addPersonalInfo: string;
    individualSearch: string;
    addEmploymentInfo: string;
    getEmployementInfo: string;
    getEmployementInfoCountByParentId: string;
    createDocumentRecord: string;
    getDocumentRecord: string;
    acceptsTou: string;
    getSignedInfo: string;
    cashAccountBalance: string;
    minimumCashAccountBalance: string;
    payrollSchedule: string;
    publishedDocuments: string;
    getDependents: string;
    getDependent: string;
    getCustomization: string;
    clientIdp: string;
    addUpdateDependent: string;
    fundsTransfer: string;
    clientVaccineReport: string;
    individualVaccineReport: string;
    communicationTemplate: string;
    generateLetterTemplate: string;
    letterRequest: string;
  }

  export interface AccountModel {
    serviceKey: string;
    BenifitKey: string;
    benifiSearchType: string;
    benifitAccounts: string;
    benefitAccountsUpdate: string;
    benifitAccountInfo: string;
    enrollBenefits: string;
    approveBenefits: string;
    benefitAccountsCount: string;
    entrySummary: string;
    entryPostingDates: string;
    payrollPostingReport: string;
    manualPosting: string;
    fundings: string;
    benefitAccountEntryEx: string;
    saveBenefitAccountEntry: string;
    listAccountEntries: string;
    benefitAccountBalanceSearch: string;
    electionSearch: string;
    electionData: string;
    payrollSchedule: string;
    allBenefitsAccount: string;
    saveElection: string;
    searchEntryPostings: string;
    searchEntries: string;
    postPayrollSchedule: string;
    getTransactionExp: string;
    getCABAccountDetails: string;
    getElectionChangeFlag: string;
    getAssociations: string;
    saveDependentAssociation: string;
  }

  export interface ConfigurationModel {
    serviceKey: string;
    benefits: string;
    benefitPlans: string;
    paymentPlans: string;
    fundingSourceAndSchedule: string;
    serviceOffering: string;
    requestType: string;
    getOverallAmount: string;
    getOfferingTaxYearLimits: string;
    getBenefitPlan: string;
    getOffering: string;
    paymentTier?: string
  }

  export interface DashboardModel {
    serviceKey: string;
    verifyPostingParticipients: string;
    contributionsByClient: string;
    reimbursment: string;
    employeesByBenefitPlan: string;
    transactionActivitySummary: string;
    accountSummaryDeduction: string;
    employeesByClient: string;
    getClients: string;
    benefitAccountSummaryByBenefitPlan: string;
    remittanceEntryAggregate: string;
    remittanceEntryDetails: string;
    fundingAndRemittanceEntries: string;
  }

  export interface SupportModel {
    serviceKey: string;
    createSupport: string;
    getSupport: string;
    search: string;
    supportAttachment: string;
    attachmentSearch: string;
    addSupportRequestComments: string;
    topics: string;
  }

  export interface CommunicationModel {
    serviceKey: string;
    alertsURI: string;
    alertsDetailsURI: string;
    alertsMarkAsReadURI: string;
    communicationRequestURI: string;
    communicationTypesURI: string;
    communicationRegistryURI: string;
  }

  export interface ReportModel {
    serviceKey: string;
    reports: string;
    reportDownload: string;
    newReport: string;
  }

  export interface PaymentAccountModel {
    serviceKey: string;
  }

  export interface ErrorCognito extends Error {
    code?: string;
  }

  export interface DateSettings {
    navigation: 'select' | 'arrows' | 'none';
    showWeekNumbers: boolean;
  }

  export interface EmploymentDetails {
    employeeId: string;
    hireDate: string;
    individualName: string;
    eligiblityDate?: string;
    employmentStatus: string;
    averageWorked?: string;
    payrollScheduleName: string;
    division: string;
    subDivision: string;
    class: string;
    nonEmployee: boolean;
  }

  export interface TargetElement {
    target?: HTMLElement | string;
  }

  export interface AdditionalColumns {
    className: boolean;
    divisionName: boolean;
    benefitPlans?: boolean;
    [key: string]: boolean;
  }

  export interface ExpenditureStatesOption {
    Paid: string;
    PaidVerified: string;
    PaidVerificationReq: string;
    PaidCustomerInputReq: string;
    [key: string]: any;
  }

  export interface ValidateSubdivision {
    id?: boolean;
  }

  export interface ResponseHeaders {
    get(name: string): number | null;
  }

  export interface NgbDate extends NativeNgbDate {
    year: number;
    month: number;
    day: number;

  }

  export interface ChartData {
    planName: string;
    amount: number;
    percentAmount?: number;
    percent?: number;
    tablePercent?: number;
  }

  export interface ConditionalColumns {
    isEmployeeDivision: boolean;
    isEmployeeEligibilityClass: boolean;
    isBenefitAccount: boolean;
  }

  export interface MultiplePlanDetails {
    planName?: string;
    participantDeductionAmount?: number;
    clientContributionAmount?: number;
  }

  export interface EnrollDependentViewModel {
    id: string;
    isEnrolled: boolean;
    name: string;
    selected: boolean;
    effectiveDate: NgbDate;
    isAddedDuringEnrollment: boolean;
  }

  export interface AddEditDependentAssociationViewModel {
    id: string;
    isEnrolled: boolean;
    name: string;
    selected: boolean;
    effectiveDate: string;
    eligibilityEndDate: string;
  }

  export interface DependentEffectiveDateValidationParams {
    effectiveDate: string;
    minDate: string;
    maxDate: string;
  }

  export interface AddDependentDialogResult {
    updatedDependent: Dependent;
    pageRefresh: boolean;
  }

  export interface EnrollmentInformationData {
    name: string;
    planStartDate: string;
    planEndDate: string;
    enrollmentMethods: Array<string>;
    allowedEnrollmentStates: Array<string>;
    requireEnrollmentApproval: boolean;
    approver: string;
    enrollmentApprovalAfterInitiation: boolean;
    enrollmentApprovalBeforeInitiation: boolean;
    enrollmentApprovalBeforeInitiationMethods: Array<string>;
    enrollmentApprovalAfterInitiationMethods: Array<string>;
    enrollmentStartDate: string;
    enrollmentEndDate: string;
  }

  export interface FileBrowse {
    uploadHeader: string;
    downloadTemplatelink: string;
    downloadTemplateDesc: string;
    fileValidationTest: string;
    dragDropText: string;
    fileRenameText: string;
    fileType: string;
    dialogData?: DialogData;
  }

  export interface UploadingFile extends DialogDataMessage {
    title: string;
    desc: string;
    view: string;
  }

  export interface DialogDataMessage {
    desc1: string;
    desc2: string;
    title: string;
    desc: string;
    view: string;
  }

  export interface FileTemplateValues {
    name: string;
  }

  export interface RequestPayload {
    key: string;
    value: string | FileTemplateValues | boolean | number;
    matchType?: MatchType;
    chainType?: ChainType;
  }

  export interface FundingMethodData {
    name: string;
    gridData?: Array<FundingDataType>;
  }

  export interface FundingDataType {
    id?: string;
    divisionName?: string;
    paymentSourceType?: string;
    bankName?: string;
    accountNumber?: string;
    fundingMethod?: string;
  }

  export interface ColumnType {
    id: string;
    name?: string;
    sortingOrder?: string;
    active?: boolean;
    [key: string]: string | boolean;
  }

  export interface PostingSummaryAmount {
    emplpyeesContribution: number;
    employeerContribution: number;
    employeeDeduction: number;
    employerFunding: number;
    totalBenefitAmount: number;
  }

  export interface SummaryData {
    entryType: string;
    planId: string;
    currentState: string;
    scheduledDate: string;
    amountSum: number;
  }

  export interface PostingSummartTooltip {
    contributions: string;
    deduction: string;
    funding: string;
  }

  export interface PostManualData {
    ClientContribution: number;
    ClientFunding: number;
    ClientPodFunding: number;
    ParticipantContribution: number;
    ParticipantToClientFunding: number;
    PayrollPosting: number;
    name: string;
    checkbox?: boolean;
    currentState?: string;
    planStatus?: string;
    scheduled: string;
    id: string;
  }

  export interface UpdateRemainingBalanceData {
    fundingAmount: number;
    isSelected: boolean
  }
  export interface SchedulePostingData {
    PostingDate: string;
    Data?: Array<PostManualData>;
  }

  export interface PostPlanData extends EntityBase {
    searchCriteria: SearchCriteria;
  }

  export interface PostingSummaryDetails {
    benefitPlans: Array<BenefitPlan>;
    postingSummary: Array<SummaryData>;
  }

  export interface ManualPostingData {
    benefitPlans: Array<BenefitPlan>;
    postingSummary: Array<Model.SummaryData>;
    selectedDate: string;
    selectedDateSummary: Array<Model.SummaryData>;
    calenderDatesToDisplay: Array<string>;
  }

  export interface EmployeesList {
    benefitAccountStatus: string;
    benefitPlanId: string;
    benefitPlanName: string;
    clientId: string;
    employeeHireDate: string;
    employeeId: string;
    employeeName: string;
    employeeStatus: string;
    employeeTascId: string;
    [key: string]: string;
  }

  export interface FundFromCabGridData {
    data?: Array<any>;
  }

  export interface VerifyPostingGridData {
    benefitAccounts: Array<VerifyPostingGridData>;
    fullName: string;
    detailRow: boolean;
    detailRowLoading: boolean;
    benefitAccountId: string;
    individualExternalId: string;
    individualId: string;
    numberOfBenefitAccounts: number;
    planName: string;
    planId?: string;
    clientContributionAmount: number;
    clientFundingAmount?: number;
    participantDeductionAmount: number;
    externalId?: string;
    actionRequired?: boolean;
    disabledFlag?: boolean;
    errorFlag?: boolean;
    rowObject?: Model.VerifyPostingGridData;
    benefitAccountEntryContribution?: Entry;
    benefitAccountEntryDeduction?: Entry;
    isBenefitAccountEntryContribution?: boolean;
    isBenefitAccountEntryDeduction?: boolean;
    listAccountEntriesContribution?: Entry[];
    listAccountEntriesDeduction?: Entry[];
    electionScheduleTypeContribution?: string;
    pendingElectionAmountContribution?: number;
    electionScheduleTypeDeduction?: string;
    pendingElectionAmountDeduction?: number;
    validationParametersContribution?: ValidationParameter;
    validationParametersDeduction?: ValidationParameter;
    errorMessage?: string;
    disabledFlagContribution?: boolean;
    disabledFlagDeduction?: boolean;
    errorFlagDeduction?: boolean;
    errorFlagContribution?: boolean;
    errorMessageContribution?: string;
    errorMessageDeduction?: string;
    entryState: string;
    noEditAllowedContribution?: boolean;
    noEditAllowedContributionMessage?: string;
    noEditAllowedDeduction?: boolean;
    noEditAllowedDeductionMessage?: string;
    noEditMessageShownOnceDeduction?: boolean;
    noEditMessageShownOnceContribution?: boolean;
    disableInput?: boolean;
    isParent?: boolean;
    maxAmountContribution?: number;
    maxAmountDeduction?: number;
    scheduledDate?: string;
    menu?: boolean;
    isPostingEditable?: boolean;
    bypassEnabled?: boolean;
    isParticipantDeductionByPassWarning?: boolean;
  }

  export interface ValidationParameter {
    electionScheduleType: string;
    electionAmount: number;
    entries: Entry[];
  }

  export interface VerifyPostingResponse {
    calenderDatesToDisplay: Set<string>;
    postingDetails: Model.SearchResults<Model.VerifyPostingGridData[]>;
    selectedDate: string;
  }

  export interface PlansData {
    externalName: string;
    planStartDate: string;
    planEndDate: string;
    externalId: string;
    electionScheduleType: string;
    limitMinimum: number;
    limitMaximum: number;
    description: string;
    hasCarryover: boolean;
    eligibilityClasses: number[];
    allowCarryOverCalcPercentageLimit: boolean;
    allowCarryOverCalcPercentageAndAmountLimit: boolean;
    allowCarryOverCalcAmountLimit: boolean;
    allowCarryoverCalcFullAvailableBalance: boolean;
    carryoverCalculationMethod: string;
    rolloverCalcMaxPercent: string;
    rolloverCalcMaxAmountWithoutPercent: string;
    gracePeriod: boolean;
    gracePeriodMaximumDays: number;
    finalExpenseDate: string;
    runOutType: string;
    runOutDays: number;
    finalSubmitDate: string;
    benefitEffectiveDate: string;
    eligibilityEndDate: string;
    finalCoverageDate: string;
  }

  export interface ConfigurationPath {
    serviceKey?: string;
    benefits?: string;
    benefitPlans?: string;
    fundingSourceAndSchedule?: string;
    serviceOffering?: string;
  }

  export interface BenefitPlanSummary extends EntityBase {
    advanceAccount?: boolean;
    allowAssociatingDependentsToBenefitAccount?: boolean;
    allowCardSubmission?: boolean;
    allowClientApproval?: boolean;
    allowClientSelfRenewal?: boolean;
    allowContributionsAfterTaxYear?: boolean;
    allowElectronicFileSubmission?: boolean;
    allowOnlineSubmission?: boolean;
    allowPaperSubmission?: boolean;
    alwaysUseLimitMaximum?: boolean;
    areCashDepositAccountsInterestBearing?: boolean;
    autoPost?: string;
    autoVerification?: boolean;
    billingTerminationRunOutType?: string;
    cardAuthorizationOptions?: Array<string>;
    cardOrderDate?: string;
    clientContribution?: boolean;
    description?: string;
    electionScheduleType?: string;
    eligibilityClasses?: Array<number>;
    eligibilityEndDateType?: string;
    employeeCount?: string;
    enrollmentApprovalAfterInitiation?: boolean;
    enrollmentApprovalAfterInitiationMethods?: Array<string>;
    enrollmentApprovalBeforeInitiation?: boolean;
    enrollmentApprovalBeforeInitiationMethods?: Array<string>;
    enrollmentEndDate?: string;
    enrollmentMethods?: Array<string>;
    enrollmentStartDate?: string;
    externalDescription?: string;
    externalId?: string;
    externalName?: string;
    externalPayeeNetworks?: Array<string>;
    finalExpenseDate?: string;
    finalSubmitDate?: string;
    firstPayrollPostingDate?: string;
    gracePeriod?: boolean;
    groupingTag?: string;
    hasCarryover?: boolean;
    hasInvestmentAccount?: boolean;
    hasTaxYearContributions?: boolean;
    limitMaximum?: number;
    limitMinimum?: number;
    name?: string;
    offeringId?: string;
    offeringName?: string;
    optionalAccountStatesToBill?: Array<string>;
    paymentSourceId?: string;
    paymentSourceType?: string;
    paymentsRequirePriorVerification?: boolean;
    payrollScheduleIds?: Array<string>;
    planEndDate?: string;
    planInitiationDate?: string;
    planStartDate?: string;
    planType?: BenefitPlanType;
    remainingRequirements?: Array<string>;
    renewalLeadTimeDays?: number;
    requireEnrollmentApproval?: boolean;
    requireSSN?: boolean;
    restrictCardIssuance?: boolean;
    sendWelcomeLettersDate?: string;
    showTaxYearContributions?: boolean;
    terminationRulesType?: string;
    terminationRunOutType?: string;
    scheduledDate?: string;
    allowCarryOverCalcPercentageLimit?: boolean;
    allowCarryOverCalcAmountLimit?: boolean;
    allowCarryOverCalcPercentageAndAmountLimit?: boolean;
    allowCarryoverCalcFullAvailableBalance?: boolean;
    carryoverCalculationMethod?: CarryoverCalculationMethodType;
    rolloverCalcMaxPercent?: string;
    carryoverMaxAmount?: string;
    gracePeriodMaximumMonths?: number;
    gracePeriodMaximumDays?: number;
    runOutType?: string;
    runOutDays?: number;
    benefitEffectiveDate?: string;
    eligibilityEndDate?: string;
    menu?: boolean;
    coverageTiers?: BenefitPlanCoverageType[];
  }

  export interface AccountPath {
    serviceKey?: string;
    BenifitKey?: string;
    benifiSearchType?: string;
    benifitAccounts?: string;
    benefitAccountsUpdate?: string;
    benifitAccountInfo?: string;
    enrollBenefits?: string;
    approveBenefits?: string;
    benefitAccountsCount?: string;
    entrySummary?: string;
    payrollPostingReport?: string;
    paymentSourceAccount?: string;
    manualPosting?: string;
    bankAccount?: string;
    fundings?: string;
  }

  export interface PostManual {
    ParticipantContribution: number;
    ClientContribution: number;
    ParticipantToClientFunding: number;
    ClientFunding: number;
    PayrollPosting: number;
    scheduled: boolean;
    id: string;
    currentState?: string;
    planStatus?: string;
    [key: string]: string | number | boolean | any;
  }

  export interface EntryBenefitDetail extends Entry {
    benefitPlanName?: string;
    className?: string;
  }

  export interface EntriesServiceArray {
    body?: Entry[];
    [key: string]: Entry[] | any;
  }

  export interface EntryResponse {
    items: BenefitAccountEntryEx[];
    total: number;
  }

  export interface ModifiedRecords {
    contribution: Model.VerifyPostingGridData[];
    deduction: Model.VerifyPostingGridData[];
  }

  export interface ModifiedRecordSingle {
    contribution: Model.VerifyPostingGridData;
    deduction: Model.VerifyPostingGridData;
  }

  export interface RequestTypeSelections {
    [key: string]: string | number;
    limit?: number;
    expenseType?: string;
    serviceType?: string;
  }

  export interface RequestTypeExpenseSelections {
    [key: string]: Array<RequestTypeSelections>;
  }

  export interface FileUploadModel {
    uploadHeader: string;
    downloadTemplatelink: string;
    downloadTemplateDesc: string;
    fileValidationTest: string;
    dragDropText: string;
    fileRenameText: string;
    fileType: string;
    dialogData: {
      uploadingFile: {
        title: string;
        desc: string;
        view: string;
      };
      errorAtUploadFile: {
        desc1: string;
        desc2: string;
        title: string;
        desc: string;
        view: string;
      };
    };
  }

  export interface EmployeeBenefitAccount extends BenefitAccount {
    payrollLastDate?: string;
    minDate?: NgbDate;
    eligibilityType?: string;
    eligibilityEndDateType?: string;
    eligibilityToggle?: boolean;
    payrollToggle?: boolean;
    fullCoverageToggle?: boolean;
    errorFlag?: boolean;
    errorFlagFullCoverage?: boolean;
    errorFlagEligibility?: boolean;
    fullCoveragePaidThroughDateKey?: string;
    fullCoveragePaidThroughDateValue?: string;
    terminationRulesType?: string;
    fullCoveragePaidThroughDate?: string;
    benefitEffectiveDateError?: boolean;
    disallowBenefitAccountInactivationDateChangesAfterPlanEndDate?: boolean;
    benefitPlanEndDateError?: boolean;
    isContributionDeductionSent?: boolean;
  }

  export interface IndividualData {
    terminationDate?: string;
    currentState?: string;
    parentId?: string;
    individualId?: string;
    planName?: string;
    eligibilityEndDateType?: string;
    eligibilityEndDate?: string;
    payrollLastDate?: string;
    planId?: string;
    individualName?: string;
    eligibilitytoggle?: boolean;
    payrolltoggle?: boolean;
    planEndDate?: string;
    errorFlag?: boolean;
    errorFlagFullCoverage?: boolean;
    errorFlagEligibility?: boolean;
    eligibilityType?: string;
    id?: string;
    minDate?: NgbDate;
    benefitEffectiveDate?: string;
    hireDate?: string;
    retired?: boolean;
    fullCoveragePaidThroughDate?: string;
    terminationRulesType?: string;
    allowCardSubmission?: boolean;
    disallowBenefitAccountInactivationDateChangesAfterPlanEndDate?: boolean;
  }

  export interface GridColumns {
    id?: string;
    name?: string;
    sortingOrder?: string;
    active?: boolean;
  }

  export interface FilterOptions {
    name: string;
    id: number;
    checked: boolean;
  }

  export interface SelectedFilterOptions {
    status: Array<boolean>;
    selectedOptions: Array<FilterOptions>;
  }

  export interface InfiniteScrollData {
    loadingFlag: boolean;
    data: Array<number>;
  }

  export interface HeadersModel {
    'Authorization': string;
    'Content-Type': string;
    [key: string]: string;
  }

  export interface II18N {
    en: WeekDaysMonths;
    [key: string]: WeekDaysMonths;
  }

  export interface WeekDaysMonths {
    weekdays?: Array<string>;
    months?: Array<string>;
  }

  export interface signUser {
    email: string;
    password: string;
    role?: string;
    rememberMe?: string;
  }

  export interface growlProps {
    message: string;
    type: boolean | string;
    undo?: boolean;
    link?: string;
    growelVisibility?: boolean;
    postManuallyLink?: boolean;
    covidResponseLink?: boolean;
  }

  export interface DocumentActionSearchResponse {
    documentActions: DocumentAction[];
  }

  export interface LoginRoutes {
    signup: string[];
    forgotPassword: string[];
    [key: string]: string[];
  }

  export interface AuthenticationData {
    Username: string;
    Password?: string;
    ValidationData?: {};
    [key: string]: string | {};
  }

  export interface AuthenticationChallenge {
    challengeType: AuthenticationChallengeType;
    identityProvider?: string;
  }

  export enum AuthenticationChallengeType {
    Failed = 'Failed',
    Password = 'Password',
    SAML = 'SAML',
    Unknown = 'Unknown',
  }

  export interface NameValueAuth {
    Name: string;
    Value: string;
  }

  export interface AcceptanceCriteria {
    atLeastSpecialChar?: boolean;
    atLeastCapitalLetter?: boolean;
    minLength?: boolean;
    atLeastLowerLetter?: boolean;
    atLeastNumber?: boolean;
  }

  export interface FaAuthentication {
    username: string;
    Session: string;
    [key: string]: string;
  }

  export interface DialogModel {
    profile?: DialogTypeModel;
    addAddress?: DialogTypeModel;
    addContact?: DialogTypeModel;
    editContact?: DialogTypeModel;
    templates?: any;
    infoSignInSecurity?: any;
    changePassword?: any;
    editMobileNumber?: any;
    postingSummary?: any;
    [key: string]: any;
  }

  export interface DialogTypeModel {
    name: any;
    maxWidth: number;
    [key: string]: any;
  }

  export interface RefreshSessionResult {
    error?: Error;
    isValidSession: boolean;
  }

  export interface ErrorCognito extends Error {
    code?: string;
  }

  export interface sortColumns {
    id: string;
    order: string;
    [key: string]: string;
  }

  // TODO: Might be able to replace this with the UBA EmploymentInfo entity
  export interface EmploymentInformation {
    benefitPlans?: Array<string>;
    clientExternalId?: string;
    clientName?: string;
    clientPayrollId?: string;
    created?: string;
    createdBy?: string;
    createdById?: string;
    currentState?: string;
    detailRow?: boolean;
    divisionId?: string;
    divisionName?: string;
    employeeId?: string;
    employeeStatus?: EmploymentStatus;
    externalId?: string;
    hireDate?: string;
    id?: string;
    individualId?: string;
    individualName?: string;
    lastTransition?: string;
    parentId?: string;
    parentType?: string;
    primaryEmail?: string;
    subdivisionId?: string;
    updated?: string;
    updatedBy?: string;
    updatedById?: string;
    version?: number;
    className?: string;
    eligibilityClassId?: number;
    [key: string]: any;
  }

  export interface EmploymentStatusFilterData {
    name: string;
    data: Array<EmploymentStatusFilteroptions>;
  }

  export interface EmploymentStatusFilteroptions {
    id?: string;
    name: string;
  }

  export interface EmploymentRelationshipFilterData {
    name: string;
    data: Array<EmploymentRelationshipFilterOption>;
  }
  export interface EmploymentRelationshipFilterOption {
    id?: string;
    name: string;
  }

  export interface MatDialogModel {
    cameFrom?: string;
    clientContacts?: Contact[];
    selectedClient?: Contact;
    idpGroups?: string;
    url?: string;
    title?: string;
    identityProvider?: string;
    emailAddress?: string;
    reportDomain?: ReportGroup;
  }

  export interface LetterRequestModel {
    title?: string;
    desc?: string;
    letterRequestData: {
      client: Client;
      individualEmploymentInformation: EmploymentInformation;
      individualDependents: Dependent[];
      communicationTypes: CommunicationType[];
      communicationRegistries: CommunicationRegistry[];
      communicationTemplates: CommunicationTemplate[];
      page: number;
      offset: number;
    },
  }

  export interface FilesGrid {
    data: Array<FileList>;
    totalCount?: number;
    length?: number;
  }

  export interface FileList {
    id: string;
    parentId: string;
    parentType: string;
    version: number;
    createdBy: string;
    createdById: string;
    created: string;
    updatedBy: string;
    updatedById: string;
    updated: string;
    currentState: FileInfoState;
    lastTransition: string;
    fileName: string;
    fileType: string;
    fileLocation: string;
    isTest: boolean;
    jobId: string;
    headers: Array<string>;
    numberOfRecordsProcessed: number;
    successfulRecordCount: number;
    totalRecordCount: number;
    [key: string]: string | number | boolean | Array<string> | any;
  }

  export interface FileListView {
    fileInfo?: FileList;
    currentStateDisplayText?: string;
    fileSummary?: FileSummaryMapped[];
    exceededThresholds?: FileSummaryMapped[];
    isErrorClass?: boolean;
    showFileSummary?: boolean;
  }

  export interface FileSummaryMapped {
    statKey: string;
    statLabel: string;
    value: string;
  }

  export interface TemplateGrid {
    data: Array<TemplateGridData>;
    totalCount?: number;
    length?: number;
  }

  export interface TemplateGridData {
    name: string;
    documentType: string;
    [key: string]: string | any;
  }

  export interface MyDouments extends EntityBase {
    category: string;
    description: string;
    documentAttachmentId: string;
    documentType: string;
    documentVersion: string;
    name: string;
    accountOffering?: string;
    [key: string]: string | number | ParentType | any;
  }

  export interface ClaimConnexConfigViewModel extends ClaimConnexConfig {
    isDefaultConfig?: boolean;
  }
  export interface ClaimConnexConfig {
    payToProvider?: boolean;
    ccxStartDate?: string;
    ccxEndDate?: string;
    carrierConfigs?: ClaimConnexCarrierConfig[];
  }
  export interface ClaimConnexCarrierConfig {
    clientCarrierId?: string;
    clientCarrierName?: string;
    clientCarrierEin?: string;
    startDate?: string;
    endDate?: string;
    medCodes?: ClaimConnexMedCode[];
    groupIds?: string[];
  }

  export interface PaymentSourceOptions {
    name?: string;
    ids?: string[];
    bankAccounts?: Array<BankAccount>;
    checked?: boolean;
  }

  export interface BankAccount {
    name?: string;
    id?: string;
    checked?: boolean;
    isVisible?: boolean;
  }

  export interface EmitSelected {
    name: string;
    status: boolean;
  }

  export interface PlanFilter {
    name?: string;
    id?: string;
  }

  export interface BankAccountFilter {
    name?: string;
    id?: string;
  }

  export interface ReportsGrid {
    data: Array<ReportList>;
    totalCount?: number;
    length?: number;
  }

  export interface ReportList {
    id: string;
    reportName: string;
    parentId: string;
    created: string;
    createdBy: string;
    createdById: string;
    currentState: string;
    reportType: string;
    reportOutputs: Array<ReportOutputs>;
    requestedFormats: Array<string>;
    updated: string;
    updatedBy: string;
    updatedById: string;
    version: number;
    totalRecordCount: number;
    [key: string]: string | Array<ReportOutputs> | Array<string> | number | any;

  }

  export interface ReportOutput {
    fileId: string;
    format: string;
  }

  export interface ReportOutputs {
    fileId: string;
    format: string;
  }

  export interface ReportSearchParams {
    clientId?: string;
    clientExternalId?: string;
    benefitPlanId?: string;
    benefitPlanName?: string;
    paymentPlanId?: string;
    paymentPlanName?: string;
    entryCreated?: DateRange;
    paymentDate?: DateRange;
    planYearEnd?: string;
    benefitAccountCreated?: DateRange;
    planEndData?: DateRange;
    [key: string]: string | DateRange | any;
    paymentPlanNames?: string[];
  }

  export interface DateRange {
    start?: string;
    end?: string;
  }

  export interface NewReport extends EntityBase {
    reportType: string;
    requestedFormats: Array<string>;
    inputParameters?: GroupCriteria[];
    reportOutputs?: ReportOutput[];
  }

  export interface SelectedMonth {
    current: object;
    next: NextDate;
  }

  export interface NextDate {
    year: number;
    month: number;
  }

  export interface ClientACHSettlementViewModel extends ClientAchSettlement {
    [key: string]: string | number | any;
  }

  export interface RemittanceEntryAggregateViewModel extends FundingAndRemittanceEntry {
    [key: string]: string | number | any;
  }

  export interface FundingAndRemittanceEntryViewModel extends FundingAndRemittanceEntry {
    [key: string]: string | number | any;
  }

  export interface DateOptions {
    fromDate?: string;
    toDate?: string;
  }

  export interface ContributionsByClient {
    clientId?: string;
    totalEmployeeContributions?: number;
    expectedEmployeeContributions?: number;
    totalEmployerContributions?: number;
    expectedEmployerContributions?: number;
    hasTakeoverFunding?: boolean;
  }

  export interface ReimbursementsByClient {
    clientId?: string;
    totalReimbursements?: number;
    cardReimbursements?: number;
    additionalVerificationReimbursements?: number;
  }

  export interface StepData {
    status: string;
    name: string;
  }

  export interface MappedBankAccount {
    bankAccountName?: string;
    bankName?: string;
    accountNumberLastFour?: string;
    sourceId?: string;
  }

  export interface ContributionHelpText {
    employeeContribution?: string;
    employerContribution?: string;
  }

  export interface TooltipData {
    message: string;
    visibility: boolean;
  }

  export interface CardModel {
    cardEnabledBenefitPlans: BenefitPlan[];
    cardType: string;
    cardMailingOption: string;
    maxAdvanceAmount: number;
    cardImageUrl?: string;
    offerAdvanceAccount?: boolean;
  }

  export interface ConfirmDialogModel {
    confirmFlag: boolean;
    data?: any;
  }

  export interface BankAccountData {
    id: string;
    parentId: string;
    parentType: string;
    version: number;
    createdBy: string;
    createdById: string;
    created: string;
    updatedBy: string;
    updatedById: string;
    updated: string;
    currentState: string;
    lastTransition: string;
    name: string;
    accountType: string;
    bankName: string;
    routingNumber: string;
    accountNumber: string;
    paymentAccountType: string;
    useForFees: boolean;
    useForFunding: boolean;
    [key: string]: string | number | boolean | any;
  }

  export interface BenefitClientData {
    clientDetails?: Client[];
    benefitPlans?: BenefitPlan[];
  }

  export interface ClassesData {
    name: string;
    waitingPeriod: number;
    eligibilityEffectiveDate: string;
    [key: string]: string | number | any;
  }

  export interface PasswordFormValue {
    newpassword?: string;
    oldpassword?: string;
    changePwd?: boolean;
    phoneno?: string;
  }

  export interface UIOption {
    value: string;
    displayName: string;
    isDefault: boolean;
    isActive: boolean;
    checked?: boolean;
    children?: UIOption[];
    hidden?: Boolean;
    tooltip?: string;
  }

  export interface ManageUsersGridData {
    fullName?: string;
    tascId?: string;
    userAccessRoles?: Array<{ displayName: string }>;
    userAccessRolesSortValue?: string;
    businessFunction?: Array<{ displayName: string }>;
    businessFunctionSortValue?: string;
    updated?: string;
    isPrimaryContact?: boolean;
    isAdmin?: boolean;
    isSelf?: boolean;
    detailRow?: boolean;
    rowObject?: Contact;
  }

  export interface MFAOptionData {
    AttributeName: string;
    DeliveryMedium: string;
  }

  export interface MFADAta {
    MFAOptions: Array<MFAOptionData>;
    UserAttributes: [{
      Name?: string;
      Value?: string;
    }];
  }

  export interface MarginOptions {
    top: number;
    right: number;
    bottom: number;
    left: number;
  }

  export interface StringIndex {
    [key: string]: string;
  }

  export interface NumberIndex {
    [key: string]: number;
  }

  export interface Files {
    fileName: string;
    fileType: string;
    file: File;
  }

  export interface SupportRequestDetails {
    supportResponse?: SupportRequest;
    planNames?: string;
    employeeName?: string;
    supportAttachment?: AttachmentViewModel[];
    [key: string]: any;
  }

  export interface AttachmentViewModel extends Attachment {
    attachmentId?: string;
  }

  export interface ContactOptions {
    phone?: boolean;
    chat?: boolean;
    supportSupport?: boolean;
    [key: string]: string | boolean;
  }

  export interface PostingSummaryViewModel {
    postingSummary: Model.SummaryData[];
    selectedDate: string;
    calenderDatesToDisplay: Set<string>;
  }

  export enum SortTypes {
    Ascending = 'asc',
    Descending = 'desc'
  }

  export enum DateSortTypes {
    Descending = 'Newest to oldest',
    Ascending = 'Oldest to newest'
  }

  export enum ContentTypeList {
    Pdf = 'application/pdf',
    Jpg = 'image/jpeg',
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    Csv = 'text/csv',
    Doc = 'application/msword'
  }

  export enum ReportCategory {
    ErrorReport = 'ErrorReport',
    Download = 'Download'
  }

  export enum EligibilityClasseEffectiveText {
    SameDay = 'Same day when waiting period ends',
    FirstDay = 'First Day after waiting period ends',
    FirstOfMonth = 'First of the month after waiting period ends'
  }

  export enum ReimbursementTimePeriod {
    LastWeek = 'LastWeek',
    LastMonth = 'LastMonth',
    LastQuarter = 'LastQuarter',
    YearToDate = 'YearToDate'
  }

  export enum ReimbursementTimePeriodLabel {
    LastWeek = 'Last week',
    LastMonth = 'Last month',
    LastQuarter = 'Last quarter'
  }

  export enum OverviewChartPeriodLabel {
    Daily = 'Days',
    Weekly = 'Weeks',
    Monthly = 'Months',
    Quarterly = 'Quaters'
  }

  export enum TopicSubtopicText {
    topic = 'Choose a topic',
    subtopic = 'Choose a sub-topic',
    label = 'How can we help you?'
  }

  export enum ReportType {
    AccountActivity = 'AccountActivity',
    continuationACAAnnual = 'continuationACAAnnual',
    continuationACAMonthly = 'continuationACAMonthly',
    Enrollment = 'Enrollment',
    Funding = 'ClientFunding',
    PaymentDetail = 'PaymentDetail',
    POD = 'POD',
    Reconciliation = 'Reconciliation',
    VeriflexForm = 'VeriflexForm',
    ReimbursementForm = 'ReimbursementForm',
    DependentCareReimbursementForm = 'DependentCareReimbursementForm',
    Finalization = 'Finalization'
  }

  export enum ReportTypeList {
    enrollment = 'enrollment',
    accountActivity = 'accountActivity',
    reconciliation = 'reconciliation',
    funding = 'clientFunding',
    clientFundingSummary = 'clientFundingSummary',
    participantBalanceSummary = 'participantBalanceSummary',
    pod = 'clientPodFunding',
    paymentDetail = 'paymentDetail',
    rateTableSummary = 'rateTableSummary',
    finalization = 'finalization',
    monthEndStatements = 'monthEndStatements',
    vaccine = 'vaccine',
    coverageChange = 'coverageChange',
    paymentAccountSummary = 'paymentAccountSummary',
    remittance = 'remittance',
    generalInitialNoticeSubstantiation = 'generalInitialNoticeSubstantiation',
    continuationACA = 'continuationACA',
    planStructure = 'planStructure',
  }

  export enum FormatType {
    PDF = 'PDF',
    CSV = 'CSV'
  }

  export enum ReportStateType {
    Submitted = 'Submitted',
    Running = 'Running',
    Ready = 'Ready',
    Error = 'Error',
    Removed = 'Removed'
  }

  export enum EnrollmentStatus {
    Active = 'active',
    Skipped = 'skipped',
    Success = 'success',
    Pending = 'pending'
  }

  export enum UserType {
    Client = 'Client',
    Employee = 'Employee',
    ExternalUser = 'External User',
    Distributor = 'Distributor',
    Carrier = 'Carrier',
    Vendor = 'Vendor'
  }

  export enum EmployeeActions {
    EditEmployee = 'Edit employee profile',
    PersonalInformation = 'Personal information',
    EmploymentInformation = 'Employment information',
    DependentInformation = 'Dependent information',
    AddEvent = 'Add Event',
    EnrollEmployee = 'Enroll employee',
    EmployeeAccountsSummary = 'Accounts summary',
    Contribute = 'Contribute to benefit account',
    EmployeeTransactions = 'Transactions',
    EligibilityEvents = 'Eligibility events',
    Letters = 'Letters',
    InvestmentActivity = 'Investments',
    TascCard = 'TASC Card',
  }

  export enum BenefitPlanActions {
    PlanSummary = 'Plan summary',
    EnrollmentInformation = 'Enrollment information',
    Coverageperiod = 'Coverage period',
    FundingMethod = 'Funding method'
  }

  export enum PaymentPlanActions {
    PlanSummary = 'Plan summary',
    Eligibility = 'Eligibility',
    Remittance = 'Remittance',
    Pricing = 'Pricing'
  }

  export enum MaxIntegerValue {
    MAX_SAFE_INTEGER = 9007199254740991
  }

  export enum ProgressSpinnerMode {
    Determinate = 'determinate',
    Indeterminate = 'indeterminate',
  }

  export enum EnrollmentState {
    Employed = 'Employed',
    Terminated = 'Terminated',
    Retired = 'Retired',
  }

  export interface ObjectReferenceTieredType {
    key?: string;
    value?: number;
  }

  export interface BenefitPlanQueryEffectiveDateGrouping {
    effectiveDate: string;
    benefitPlanIds: string[];
  }

  export interface ScheduleAndIsFutureElections {
    futureSchedule: PayrollSchedule,
    isFutureClientElection: boolean,
    isFutureIndividualElection: boolean
  }

  export type DateType = string | dayjs.Dayjs | Date;

  export enum DayJsInclusivity {
    ExclusiveStartExclusiveEnd = '()',
    ExclusiveStartInclusiveEnd = '(]',
    InclusiveStartExclusiveEnd = '[)',
    InclusiveStartInclusiveEnd = '[]',
  }
}
